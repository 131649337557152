import colors from './colors'

const style = `
  * {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    margin: 0px;
    padding: 0px;
    color: inherit;
    line-height: 1.5rem;
    box-sizing: border-box;
    text-decoration: none;
    transition-property: background-color, color, border, box-shadow, border-color;
    transition-duration: 0.2s;
  }
  
  a:visited, a {
    color: inherit;
  }

  body, html, #documirRoot {
    background-color: ${colors.background};
    color: ${colors.black};
    margin: 0;
  }
  
  .cdx-block {
    line-height: 1.5rem !important;
    padding: 0.5rem 0;
  }
  
  .image-tool__caption, .embed-tool__caption {
    display: none;
  }
  
  h1.ce-header {
    font-size: 2rem;
    padding: 0.5rem 0;
  }
  
  h2.ce-header {
    font-size: 1.75rem;
    padding: 0.5rem 0;
  }
  
  h3.ce-header {
    font-size: 1.5rem;
    padding: 0.5rem 0;
  }
  
  h4.ce-header {
    font-size: 1.25rem;
    padding: 0.5rem 0;
  }
  
  h5.ce-header {
    font-size: 1.125rem;
    padding: 0.5rem 0;
  }
  
  h6.ce-header {
    font-size: 1rem;
    padding: 0.5rem 0;
  }
`

export default style
