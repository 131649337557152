import Snackbar from '@mui/material/Snackbar'

const SnackbarComponent = ({ onClose, open, message }) => {
  return (
    <Snackbar
      onClose={() => onClose(false)}
      open={open}
      autoHideDuration={6000}
      message={message}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    />
  )
}

export default SnackbarComponent
