import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Button from './Button'

const MenuItem = ({ children, ...props }) => {
  return (
    <div>
      <Button width='100%' textAlign='left' {...props}>
        {children}
      </Button>
    </div>
  )
}

export default MenuItem
