import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import supabase from '../supabase'
import UserContext from '../contexts/UserContext'
import Button from './Button'
import Input from './Input'
import Spacing from './Spacing'
import Card from './Card'
import colors from '../colors'
import spacing from '../spacing'
import Tabs from './Tabs'
import ListItem from './ListItem'
import Modal from './Modal'
import userRoles from '../enums/userRoles'
import Tooltip from './Tooltip'
import Teams from './Teams'
import { CircularProgress } from '@mui/material'

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`

const InputContainer = styled.div`
  flex: 1;
`

const Member = styled.div`
  margin-top: ${spacing[2]};
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  padding: ${spacing['1/2']} ${spacing['1']};
`

let userToRemove, userUpgradeParams

const enums = {
  MEMBERS: 'MEMBERS',
  TEAMS: 'TEAMS',
  ALERTS: 'ALERTS'
}

export default function Organization () {
  const [email, setEmail] = useState()
  const [tab, setTab] = useState(enums.MEMBERS)
  const [members, setMembers] = useState()
  const [inviteeRole, setInviteeRole] = useState(userRoles.EDITOR)
  const [invitations, setInvitations] = useState()
  const [invitationLoading, setInvitationLoading] = useState(false)
  const [organisationId, setOrganizationId] = useState()
  const { user, updateUser, setOnboardingUpdatedAt } = useContext(UserContext)
  const [remindersEnabled, setRemindersEnabled] = useState()
  const [membersTab, setMembersTab] = useState('members')
  const [openModal, setOpenModal] = useState(false)
  const [openModalInvite, setOpenModalInvite] = useState(false)
  const [openModalRemove, setOpenModalRemove] = useState(false)
  const [openModalUpgrade, setOpenModalUpgrade] = useState(false)

  const tabs = [
    { label: 'Membres', value: enums.MEMBERS },
    { label: 'Équipes', value: enums.TEAMS }
  ]

  if (organisationId && user?.role === userRoles.ADMIN) {
    tabs.push({ label: 'Paramètres', value: enums.ALERTS })
  }

  const getMembers = async () => {
    if (user?.id) {
      const { data: membersData } = await supabase
        .from('users')
        .select(`
          organization_id (
            id,
            reminders_enabled,
            teams (
              *,
              users_to_teams (
                *,
                users (
                  *
                )
              )
            ),
            users (
              *
            )
          )
        `)
        .eq('id', user?.id)

      setOrganizationId(membersData[0]?.organization_id?.id)
      setMembers(membersData[0]?.organization_id?.users.sort((a, b) => a.email.localeCompare(b.email)))
      setRemindersEnabled(membersData[0]?.organization_id?.reminders_enabled)
      getInvitations()
    }
  }

  const getInvitations = async () => {
    if (organisationId) {
      const { data } = await supabase
        .from('invitations')
        .select()
        .eq('organization_id', organisationId)

      setInvitations(data.filter(invitation => !members.map(member => member.email).includes(invitation.email)))
    }
  }

  useEffect(() => {
    getMembers()

    if (user?.role === userRoles.USER && user?.organization_id && inviteeRole !== userRoles.USER) {
      setInviteeRole(userRoles.USER)
    }
  }, [user, organisationId])

  useEffect(() => {
    getInvitations()
  }, [members])

  const handleInvite = async () => {
    setInvitationLoading(true)

    const { data: userData } = await supabase
      .from('users')
      .upsert({ id: user?.id })

    let organizationId

    if (!userData[0].organization_id) {
      const { data: organizationData } = await supabase
        .from('organizations')
        .insert([{}])

      await supabase
        .from('users')
        .update({ organization_id: organizationData[0].id, role: userRoles.ADMIN })
        .match({ id: user?.id })

      organizationId = organizationData[0].id
      updateUser()
    } else {
      organizationId = userData[0].organization_id
    }

    fetch((window.location.hostname.includes('localhost') ? 'http://localhost:3000/api/send-invitation?' : 'https://www.documir.com/api/send-invitation?') + new URLSearchParams({
      email,
      organization_id: organizationId,
      from: user?.email,
      role: inviteeRole
    }))
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setOpenModal("Un email d'invitation vient d'être envoyé !")
          setOrganizationId(organizationId)
          getMembers()
          setEmail('')
        } else {
          setOpenModal(data.message)
        }
      })
      .finally(() => {
        setOpenModalInvite(false)
        setInvitationLoading(false)
      })

    setOnboardingUpdatedAt && setOnboardingUpdatedAt(new Date())
  }

  const handleRemove = async id => {
    setOpenModalRemove(false)

    await supabase
      .from('users_to_teams')
      .delete()
      .match({ user_id: id })

    await supabase
      .from('documents')
      .update({ user_id: user?.id })
      .match({ user_id: id })

    await supabase
      .from('users')
      .update({
        organization_id: null,
        role: userRoles.USER
      })
      .match({ id })

    getMembers()
  }

  const handleUpdateReminders = async event => {
    event.preventDefault()

    await supabase
      .from('organizations')
      .update({ reminders_enabled: event.target.checked })
      .match({ id: organisationId })

    getMembers()
  }

  const handleRoleUpdate = async ({ event, memberId, role }) => {
    event.preventDefault()
    setOpenModalUpgrade(false)

    await supabase
      .from('users')
      .update({
        role
      })
      .match({ id: memberId })

    getMembers()
  }

  return (
    <div>
      <Modal open={openModal} onClose={setOpenModal} hideHeader doublePadding>
        {openModal}
      </Modal>

      <Modal open={openModalInvite} onClose={setOpenModalInvite} hideHeader doublePadding confirmButtonText='Inviter' onConfirm={invitationLoading ? null : handleInvite}>
        <InputContainer>
          {
            invitationLoading
              ? (
                <div style={{ margin: 'auto', display: 'block', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
                )
              : (
                <>
                  <Input autoFocus value={email} onChange={e => setEmail(e.target.value)} label='Adresse email de la personne à inviter' fullWidth placeholder='Son adresse email' />

                  {(user?.role !== userRoles.USER || !user?.organization_id) && (
                    <>
                      <Spacing y={2} />

                      <Input value={inviteeRole} onChange={e => setInviteeRole(e.target.value)} label='Rôle de la personne à inviter' fullWidth type='select'>
                        <option value={userRoles.USER}>Lecteur</option>
                        <option value={userRoles.EDITOR}>Éditeur</option>

                        {user?.role === userRoles.ADMIN && (
                          <option value={userRoles.ADMIN}>Admin</option>
                        )}
                      </Input>
                    </>
                  )}
                </>
                )
          }
        </InputContainer>
      </Modal>

      <Modal open={openModalRemove} onClose={setOpenModalRemove} hideHeader doublePadding onConfirm={() => handleRemove(userToRemove)}>
        Retirer cet utilisateur de l'organisation ?
      </Modal>

      <Modal open={openModalUpgrade} onClose={setOpenModalUpgrade} hideHeader doublePadding onConfirm={() => handleRoleUpdate(userUpgradeParams)}>
        Êtes-vous sûr de donner tous les droits à cet utilisateur ? Vous ne pourrez pas revenir en arrière.
      </Modal>

      {/* <Onboarding /> */}

      <Tabs
        activeTab={tab}
        tabs={
          (user?.organization_id || organisationId) && user.role !== userRoles.USER
            ? tabs
            : tabs.filter(tab => tab.value !== enums.TEAMS)
        }
        setTab={setTab}
      />

      <Spacing block y={2} />

      {tab === enums.MEMBERS && (

        <Card header='🧑‍💼 Membres de mon organisation' helper='Ajoutez de nouveaux membres pour qu’ils aient accès à la documentation des autres membres de l’organisation'>
          <Container>
            <Button icon='send_white.svg' onClick={() => setOpenModalInvite(true)} width='100%' primary>Inviter un collègue</Button>
          </Container>

          <Spacing block y='1/2' />
          <Tabs tabs={[{ value: 'members', label: 'Membres actifs' }, { value: 'invitations', label: 'Invités' }]} activeTab={membersTab} setTab={setMembersTab} />

          <div>
            {membersTab === 'members'
              ? (
                  !members
                    ? <div style={{ marginTop: spacing[2] }}>Il n'y a personne dans votre organisation pour l'instant</div>
                    : members.map(member => (
                      <Member key={member.id}>
                        <div style={{ flex: '1 1 100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          <span>
                            {member.email}
                          </span>
                          <br />
                          <span style={{ color: colors.grey, fontSize: '0.9375rem' }}>
                            Dernière visite : {
                          new Date(member.last_visit).toLocaleDateString('fr')
                        }
                          </span>

                        </div>

                        <div style={{ flexShrink: '0' }}>
                          {user?.role === userRoles.ADMIN && member.role !== userRoles.ADMIN && (
                            <Button small icon='delete_black.svg' onClick={() => { userToRemove = member.id; setOpenModalRemove(true) }} />
                          )}

                          <Tooltip text={user?.role !== userRoles.ADMIN ? 'Seuls les administrateurs de votre organisation peuvent modifier cela' : member.role === userRoles.ADMIN ? "Vous ne pouvez pas modifier le rôle d'un autre administrateur" : null}>
                            <Input
                              onChange={e => {
                                userUpgradeParams = { memberId: member.id, role: e.target.value, event: e }

                                if (userUpgradeParams.role === userRoles.ADMIN) {
                                  setOpenModalUpgrade(true)
                                } else {
                                  handleRoleUpdate(userUpgradeParams)
                                }
                              }}
                              disabled={user?.role !== userRoles.ADMIN || member.role === userRoles.ADMIN}
                              type='select'
                              value={member.role}
                            >
                              <option value={userRoles.USER}>Lecteur</option>
                              <option value={userRoles.EDITOR}>Éditeur</option>
                              <option value={userRoles.ADMIN}>Admin</option>
                            </Input>
                          </Tooltip>
                        </div>
                      </Member>
                    ))
                )
              : (
                <>
                  {!invitations?.length && (
                    <>
                      <Spacing y={2} block />
                      <div>Personne n'a encore été invité</div>
                    </>
                  )}

                  {invitations?.map(invitation => (
                    <ListItem
                      key={invitation.id}
                      margin={`${spacing[2]} 0 0 0`}
                    >
                      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {invitation.email}
                      </span>
                    </ListItem>
                  ))}
                </>
                )}
          </div>
        </Card>
      )}

      {tab === enums.TEAMS && (
        <Teams />
      )}

      {tab === enums.ALERTS && (
        <>
          <Card whiteHeader isCheckbox onChange={handleUpdateReminders} checked={remindersEnabled} header='Activer les emails hebdomadaires de rappel pour les documentations non validées' helper="Un email de rappel listant toutes les documentations non validées sera envoyé à chaque membre de l'organisation. En plus de cela, les admins recevront un email récapitulatif des documentations non validées par les autres membres." />
        </>
      )}
    </div>
  )
}
