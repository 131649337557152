import styled from 'styled-components'

const Styled = styled.div`
  display: inline-block;
  font-weight: ${props => props.heading ? '600' : '400'};
  font-size: ${props => props.heading ? '1.5rem' : '1rem'};
  line-height: ${props => props.heading ? '2rem' : '1.5rem'};
  text-align: ${props => props.textAlign ? props.textAlign : 'left'}
`

export default function Typography (props) {
  return (
    <Styled {...props} />
  )
}
