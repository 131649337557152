import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import urlHelpers from '../urlHelpers'
import Menu from './Menu'

const StyledButton = styled.a`
  text-align: center !important;
  min-height: 1.75rem;

  ${props => props.textAlign
? `
  justify-content: ${props.textAlign === 'left' ? 'start' : props.textAlign};
  `
: `
    justify-content: center;
  `}

  ${props => props.primary
    ? `
      &:hover {
        background-color: ${colors.primaryDark} !important;
      }
    `
: props.primaryLight
? `
      &:hover {
        text-decoration: underline;
      }
    `
: `
      &:hover {
        background-color: ${colors.lightGrey} !important;
      }
    `
  }


  ${props => !props.textButton && !props.transparent && `
    background-color: ${props.primary ? colors.primary : props.secondary ? colors.white : props.primaryLight ? colors.primaryLight : colors.white} !important;
    border: 1px solid ${props.primary ? colors.primaryDark : props.secondary ? colors.lightGrey : props.primaryLight ? colors.primary : 'transparent'} !important;
    padding: ${props.smallHeight ? '0px' : spacing['1/2']} ${props.small ? spacing[1] : spacing[2]} !important;
    color: ${props.primary ? colors.white : props.primaryLight ? colors.primary : colors.black} !important;
  `}

  ${props => props.width && `
    width: ${props.width} !important;
  `}

  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer !important;

  ${props => props.smallText && `
    &, & * {
      font-size: 15px !important;
    }
  `}
  
  ${props => props.menu && `
    border-radius: ${spacing['1/2']} 0px 0px ${spacing['1/2']} !important;
  `}
  
  ${props => !props.menu && `
    border-radius: ${spacing['1/2']} !important;
  `}
`

const Image = styled.img`
  height: 1rem !important;
  vertical-align: middle !important;
  
  ${props => !props.noText && `
    margin-right: ${spacing['1']}  !important;
  `}
`

const Text = styled.span`
  display: inline-block  !important;
  text-align: left;
  vertical-align: text-top  !important;

  ${props => props.textButton && `
    text-decoration: underline !important;
  `}
`

const More = styled.span`
  background-color: ${colors.primary};
  color: white;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0rem 0.5rem;
  border: 1px solid ${colors.primaryDark};
  margin-left: 1px;
  border-radius: 0px ${spacing['1/2']} ${spacing['1/2']} 0px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.primaryDark};
  }
`

export default function Button ({
  children,
  icon,
  textButton,
  textAlign,
  smallText,
  small,
  smallHeight,
  menu,
  ...props
}) {
  return (
    <>
      <StyledButton
        smallText={smallText}
        small={small}
        smallHeight={smallHeight}
        textAlign={textAlign}
        menu={menu}
        textButton={textButton}
        {...props}
      >
        {icon && (
          <Image
            noText={!children}
            src={urlHelpers.buildUrl(urlHelpers.paths.BASE) + icon}
          />
        )}

        {children && (
          <Text textButton={textButton}>
            {children}
          </Text>
        )}
      </StyledButton>

      {menu && (
        <Menu
          Content={menu}
        >
          <More>
            <img
              style={{
                height: '1.25rem'
              }}
              src='/expand_more_white.svg'
            />
          </More>
        </Menu>
      )}
    </>
  )
}
