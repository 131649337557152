
import { createClient } from '@supabase/supabase-js'

export default createClient(
  'https://ehaichugcsvmfmguwety.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVoYWljaHVnY3N2bWZtZ3V3ZXR5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTU2NTg4ODMsImV4cCI6MTk3MTIzNDg4M30.HEHrSYcSe4tbMq815pK1z5iHzcUGdjkc5trcbfyv-9k',
  {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false
  }
)
