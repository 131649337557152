import ConditionsLevel from './ConditionsLevel'
import optionsEnums from '../enums/conditionOptions'
import createDefaultCondition from '../createDefaultCondition'
import Input from './Input'
import Spacing from './Spacing'
import Button from './Button'
import styled from 'styled-components'
import spacing from '../spacing'

const ValueContainer = styled.div`
  display: ${props => props.type === optionsEnums.SUB_CONDITIONS ? 'block' : 'flex'};
  align-items: center;
`

const ValueContainerFirst = styled.div`
  flex: 1;
  margin-right: ${props => props.type === optionsEnums.SUB_CONDITIONS || props.isLast ? spacing[0] : spacing[1]};
  margin-bottom: ${props => props.type === optionsEnums.SUB_CONDITIONS ? spacing[1] : '0px'};
`

const DeleteContainer = styled.div`
  display: block;
  ${props => props.type === optionsEnums.SUB_CONDITIONS && `margin-left: ${spacing[4]};`}
`

export default function Condition ({ condition, onChange, onDelete, isLast, level, isFirst }) {
  const handleChange = ({ value, type }) => {
    onChange({
      type: typeof type === 'undefined' ? condition.type : type,
      value: typeof value === 'undefined' ? condition.value : value,
      id: condition.id
    })
  }

  return (
    <>
      <Input width={isFirst ? '100%' : 'calc(100% - 4.5rem)'} type='select' defaultValue={condition.type} onChange={e => handleChange({ type: e.target.value, value: null })}>
        {isFirst && isLast && level < 2 && (
          <option value={optionsEnums.ALWAYS}>Sur chaque page visitée</option>
        )}

        <option value={optionsEnums.URL_CONTAINS}>Quand le lien de la page contient</option>
        <option value={optionsEnums.PAGE_BODY_CONTAINS}>Quand le contenu de la page contient</option>

        {level < 2 && (
          <option value={optionsEnums.SUB_CONDITIONS}>Nouveau groupe de sous-conditions</option>
        )}

        {isFirst && isLast && level < 2 && (
          <option value={optionsEnums.NEVER}>Sur aucune page visitée</option>
        )}
      </Input>

      {condition.type !== optionsEnums.NEVER && condition.type !== optionsEnums.ALWAYS && (
        <>
          <Spacing block y={1} />

          <ValueContainer type={condition.type}>
            <ValueContainerFirst isLast={isLast} type={condition.type}>
              {
                condition.type === optionsEnums.SUB_CONDITIONS
                  ? <ConditionsLevel conditions={condition.value || createDefaultCondition(false, false)} setConditions={value => handleChange({ value })} level={level + 1} />
                  : <Input fullWidth type='text' placeholder={'Texte contenu dans ' + (condition.type === optionsEnums.URL_CONTAINS ? 'le lien de la page, exemple : documir.com' : 'le contenu de la page, exemple : Documir')} defaultValue={condition.value} onChange={event => handleChange({ value: event.target.value })} />
              }
            </ValueContainerFirst>

            {!isLast && (
              <DeleteContainer type={condition.type}>
                <Button width='100%' secondary icon='delete_black.svg' onClick={() => onDelete(condition.id)} />
              </DeleteContainer>
            )}
          </ValueContainer>
        </>
      )}
    </>
  )
}
