const colors = {
  badgeRed: '#C00',
  badgeGreen: 'green',
  primary: '#e16259',
  green: '#00A66E',
  placeholder: '#CCC',
  primaryDark: '#be5643',
  primaryLight: '#fdf5f2',
  secondary: '#FFEFD5',
  // background: '#fffefc',
  background: '#f9f5f1',
  lightGrey: '#e9e9e7',
  oldVeryLightGrey: '#f6f6f6',
  veryLightGrey: '#fffefc',
  // veryLightGrey: '#f9f5f1',
  grey: '#999',
  beige: '#fffefc',
  // beige: '#fffefc',
  white: 'white',
  blue: '#2eaadc',
  blueLight: '#e9f6fb',
  black: 'black'
}

export default colors
