import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import HomePage from './components/HomePage'
import Documents from './components/Documents'
import Settings from './components/Settings'
import {
  createHashRouter,
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import DocumentPage from './components/DocumentPage'
import EditDocument from './components/EditDocument'
import currentEnvironment from './currentEnvironment'
import environment from './enums/environments'
import Organization from './components/Organization'

const router = (currentEnvironment === environment.EXTENSION ? createHashRouter : createBrowserRouter)([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/app',
    element: <App><Documents /></App>,
    errorElement: <App />
  },
  {
    path: '/app/login',
    element: <App />,
    errorElement: <App />
  },
  {
    path: '/app/signup',
    element: <App />,
    errorElement: <App />
  },
  {
    path: '/app/documentation/:id/edit',
    element: <App><EditDocument /></App>
  },
  {
    path: '/app/documentation/new',
    element: <App><EditDocument /></App>
  },
  {
    path: '/app/documentation/:id',
    element: <App><DocumentPage /></App>
  },
  {
    path: '/app/settings',
    element: <App><Settings /></App>
  },
  {
    path: '/app/organization',
    element: <App><Organization /></App>
  },
  {
    path: '/*',
    element: <App />
  }
])

const root = ReactDOM.createRoot(document.getElementById('documirRoot'))
root.render(<RouterProvider router={router} />)
