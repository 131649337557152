
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom'
import { useEffect } from 'react'
import colors from '../colors'
import spacing from '../spacing'

export default function Tooltip ({ children, text }) {
  const { x, y, reference, floating, strategy, refs, update } = useFloating({
    placement: 'bottom',
    strategy: 'fixed',
    middleware: [offset(8), flip(), shift()]
  })

  const showTooltip = (event) => {
    refs.floating.current.style.display = 'inline-block'
    update()
  }

  const hideTooltip = (event) => {
    if (refs.floating.current) {
      refs.floating.current.style.display = 'none'
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', hideTooltip)
  }, [])

  return (
    <>
      <div
        onBlur={hideTooltip}
        onMouseLeave={hideTooltip}
        onPointerLeave={hideTooltip}
        onFocus={showTooltip}
        onMouseEnter={showTooltip}
        onPointerEnter={showTooltip}
        ref={reference}
        style={{ display: 'inline-block', lineHeight: spacing[2] }}
      >
        {children}
      </div>

      {text && (
        <div
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            backgroundColor: colors.black,
            color: colors.white,
            textAlign: 'left',
            maxWidth: spacing[64],
            minWidth: spacing[32],
            borderRadius: spacing['1/2'],
            padding: `${spacing['1']} ${spacing['2']}`,
            display: 'none',
            zIndex: '99999'
          }}
        >
          {text}
        </div>
      )}
    </>
  )
}
