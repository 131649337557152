import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Spacing from './Spacing'

const Container = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  display: inline-flex;
  max-width: 100%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
`

const Tab = styled.span`
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: inline-block;
  text-align: left;
  flex-shrink: 0;
  position: relative;
  color: ${colors.grey};
  padding: ${spacing[1]} 0;

  ${props => props.active && `
    border-bottom-color: ${colors.black};
    color: ${colors.black};
    `}

  &:hover {
    color: ${colors.black};
    border-bottom-color: ${colors.black};
  }

  ${props => !props.isFirst && `
    margin-left: ${spacing[2]}
  `}
`

export default function Tabs ({ tabs, setTab, activeTab }) {
  return (
    <Container>
      <Spacing block y='1' />

      {tabs.map((tab, index) => (
        <Tab
          isLast={index === (tabs.length - 1)}
          isFirst={index === 0}
          key={tab.value}
          active={activeTab === tab.value}
          onClick={() => setTab(tab.value)}
        >
          {tab.label}

          {tab.redDot || tab.greenDot
            ? (
              <span
                style={{
                  display: 'inline-block',
                  width: spacing['1'],
                  backgroundColor: tab.redDot ? colors.primary : colors.green,
                  height: spacing['1'],
                  position: 'absolute',
                  top: '2px',
                  right: '0px',
                  borderRadius: '100rem'
                }}
              />
              )
            : null}
        </Tab>
      ))}
    </Container>
  )
}
