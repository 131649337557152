const urlHelpers = {
  paths: {
    BASE: '/',
    ORGANIZATION: '/app/organization',
    EditDocument: id => `/app/documentation/${id}/edit`
  },
  buildUrl: (path) => {
    return (
      window.location.origin.includes('localhost')
        ? 'http://localhost:3000'
        : 'https://www.documir.com'
    ) + path
  }
}

export default urlHelpers
