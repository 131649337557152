import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import colors from '../colors'
import Spacing from './Spacing'
import spacing from '../spacing'
import { useContext, useEffect, useState } from 'react'
import supabase from '../supabase'
import UserContext from '../contexts/UserContext'
import userRoles from '../enums/userRoles'
import sendMessageToExtension from '../sendMessageToExtension'

const steps = [
  {
    label: 'Lisez la documentation de Documir',
    description: <span>Retrouvez-la parmi vos autres documentations.</span>
  },
  {
    label: 'Ajoutez ou importez une documentation',
    description: <span>Pour cela, cliquez sur le bouton de la page "Documentations".</span>
  },
  {
    label: 'Invitez un collègue',
    description: <span>Allez dans les paramètres de votre organisation pour inviter un collègue sur Documir.</span>
  },
  {
    label: 'Créez une équipe',
    description: <span>Créez une équipe depuis les paramètres de votre organisation pour préciser qui doit connaître telle ou telle documentation.</span>
  },
  {
    label: "Installez l'extension Documir",
    description: <span>Vous pouvez l’installer depuis <a style={{ color: colors.blue, textDecoration: 'underline' }} href='https://chrome.google.com/webstore/detail/documir/hdjcojhpfphdjolncenekanikehggnah?hl=fr' target='_blank' rel='noreferrer'>cette page</a>, elle vous donnera accès à l’ensemble des fonctionnalités de Documir.</span>
  }
]

const Onboarding = () => {
  const [data, setData] = useState()
  const [shouldBeLoaded, setShouldBeLoaded] = useState(false)
  const [hide, setHide] = useState()
  const [extensionIsInstalled, setExtensionIsInstalled] = useState(null)
  const { user, onboardingUpdatedAt } = useContext(UserContext)
  const shouldShowOnboarding = data && (data.onboarding_status || !data.organization_id) && (extensionIsInstalled !== null || shouldBeLoaded) && !hide && data.onboarding_status !== '5'
  let step = 0

  if (data?.documir_documentation_read) {
    step = 1

    if (data?.documentation_added) {
      step = 2

      if (data?.colleague_invited) {
        step = 3

        if (data?.team_created || user?.role === userRoles.USER) {
          step = 4

          if (extensionIsInstalled || 'ontouchstart' in document.documentElement) {
            step = 5
          }
        }
      }
    }
  }

  const updateOnboardingStatus = async () => {
    await supabase
      .from('users')
      .update({ onboarding: step })
      .match({ id: user.id })
  }

  useEffect(() => {
    if (shouldShowOnboarding) {
      updateOnboardingStatus()
    }
  }, [step, shouldShowOnboarding])

  const getOnboardingData = async () => {
    const { data } = await supabase
      .from('onboardings')
      .select()
      .eq('id', user.id)
      .limit(1)
      .single()

    setData(data)
  }

  useEffect(() => {
    if (user) {
      getOnboardingData()
    }
  }, [user, onboardingUpdatedAt])

  useEffect(() => {
    sendMessageToExtension({
      message: 'isDocumirInstalled',
      callback: isInstalled => {
        if (isInstalled) {
          setExtensionIsInstalled(true)
        }
      }
    })

    setTimeout(() => {
      setShouldBeLoaded(true)
    }, 100)
  }, [])

  return shouldShowOnboarding && (
    <>
      <div style={{ border: `1px solid ${colors.blue}`, borderRadius: '4px', padding: `${spacing[1]} ${spacing[2]}`, backgroundColor: colors.blueLight, position: 'relative' }}>
        <span
          style={{ color: colors.blue, position: 'absolute', right: '0px', top: '0px', padding: `${spacing[1]} ${spacing[2]}`, cursor: 'pointer' }}
          onClick={() => setHide(true)}
        >
          X
        </span>

        <div style={{ color: colors.blue, textAlign: 'left' }}>
          {step === 5 ? 'Félicitations, vous avez terminé notre onboarding !' : '2 minutes pour prendre en main Documir'}
        </div>

        <Spacing y={2} block />

        <Stepper activeStep={step}>
          {steps.map(step => (
            <Step key={step.label}>
              <StepLabel />

              {/* {step.description && (
                <StepContent>{step.description}</StepContent>
              )} */}
            </Step>
          ))}
        </Stepper>

        <Spacing y={2} />

        {step !== 5 && (
          <div>
            <span style={{ fontWeight: 600 }}>{steps[step].label}</span>
            {' '}
            <span style={{ color: colors.grey }}>
              : {steps[step].description}
            </span>
          </div>
        )}
      </div>

      <Spacing y={2} />
    </>
  )
}

export default Onboarding
