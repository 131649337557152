import styled from 'styled-components'

const Styled = styled.a`
  color: inherit;

  ${props => props.underlined && `
    text-decoration: underline;
  `}

  &:visited, &:hover, &:active, &:focus {
    color: inherit;
  }
`

export default function Link (props) {
  return (
    <Styled {...props} />
  )
}
