import { useState } from 'react'
import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import supabase from '../supabase'
import Button from './Button'
import Input from './Input'
import Modal from './Modal'
import Spacing from './Spacing'

const Container = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  margin-bottom: ${spacing['2']};
  
  ${props => props.isOpen && `
    box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.05);
  `}
`

const Member = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  padding-left: ${spacing[1]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing[2]};
`

const Header = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${spacing['1/2']} ${spacing[1]} ${spacing['1/2']} ${spacing[2]};
  align-items: center;
  justify-content: space-between;
  border-bottom: 0px solid transparent;
  
  ${props => props.isOpen && `
    border-bottom: 1px solid ${colors.lightGrey};
  `}
`

const Body = styled.div`
  padding: ${spacing[2]};
`

const AddContainer = styled.span`
  display: flex;
  align-items: center;
`

let userToDelete, newName

export default function Team ({ team, onUpdated, organizationMembers, teamMembers }) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [openModalDeleteTeam, setOpenModalDeleteTeam] = useState(false)
  const [openModalRemoveUser, setOpenModalRemoveUser] = useState(false)
  const [openModalAddUser, setOpenModalAddUser] = useState(false)
  const [openModalRename, setOpenModalRename] = useState(false)
  const notTeamMembers = organizationMembers.filter(member => !teamMembers.map(el => el.email).includes(member.email))

  const handleRename = async () => {
    setOpenModalRename(false)

    await supabase
      .from('teams')
      .update({ name: newName })
      .match({ id: team.id })

    onUpdated && onUpdated()
  }

  const handleDelete = async () => {
    setOpenModalDeleteTeam(false)

    await supabase
      .from('teams')
      .delete()
      .match({ id: team.id })

    onUpdated && onUpdated()
  }

  const handleAddMember = async () => {
    setOpenModalAddUser(false)

    if (selectedMember) {
      await supabase
        .from('users_to_teams')
        .insert({
          user_id: selectedMember,
          team_id: team.id
        })

      onUpdated && onUpdated()
      setSelectedMember(null)
    } else {
      setOpenModal("Merci de sélectionner un membre pour l'ajouter à cette équipe")
    }
  }

  const handleRemoveMember = async (userId) => {
    setOpenModalRemoveUser(false)

    await supabase
      .from('users_to_teams')
      .delete()
      .match({ team_id: team.id, user_id: userId })

    onUpdated && onUpdated()
  }

  return (
    <Container isOpen={isOpen}>
      <Modal open={openModal} onClose={setOpenModal} hideHeader doublePadding>
        {openModal}
      </Modal>

      <Modal open={openModalAddUser} onClose={setOpenModalAddUser} confirmButtonText='Ajouter' onConfirm={handleAddMember} hideHeader doublePadding>
        <Input style={{ flexGrow: 1, flexShrink: 1, width: '100%' }} label="Membre à ajouter à l'équipe" onChange={e => setSelectedMember(e.target.value)} type='select'>
          <option value={null}>-- Choisir un membre à ajouter --</option>
          {notTeamMembers.map(member => <option key={member.id} value={member.id}>{member.email}</option>)}
        </Input>
      </Modal>

      <Header isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {team.name}
        <Button small icon={isOpen ? 'expand_less_black.svg' : 'expand_more_black.svg'} />
      </Header>

      <Modal open={openModalRename} onClose={setOpenModalRename} hideHeader doublePadding onConfirm={handleRename}>
        <Input type='text' defaultValue={team.name} label="Nouveau nom de l'équipe" width='100%' placeholder='Exemple : Support client' onChange={e => { newName = e.target.value }} />
      </Modal>

      <Modal open={openModalDeleteTeam} onClose={setOpenModalDeleteTeam} hideHeader doublePadding onConfirm={handleDelete}>
        Supprimer l'équipe ?
      </Modal>

      <Modal open={openModalRemoveUser} onClose={setOpenModalRemoveUser} hideHeader doublePadding onConfirm={() => handleRemoveMember(userToDelete)}>
        Retirer le membre de l'équipe ?
      </Modal>

      {isOpen && (
        <Body>
          <div>
            <AddContainer>
              <Button style={{ flexShrink: 0 }} width='100%' icon='add_primary.svg' primaryLight onClick={() => setOpenModalAddUser(true)}>Ajouter un membre</Button>
            </AddContainer>

            <Spacing block y='2' />

            {teamMembers && teamMembers.map(member => (
              <Member key={member.id}>
                <span style={{ flex: '1 1 100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{member.email}</span>
                <Button small icon='highlight_off_black.svg' onClick={() => { userToDelete = member.id; setOpenModalRemoveUser(true) }} />
              </Member>
            ))}

          </div>
          <Button width={`calc(50% - ${spacing['1']})`} icon='edit_black.svg' secondary onClick={setOpenModalRename}>Renommer</Button>
          <Spacing x='2' />
          <Button width={`calc(50% - ${spacing['1']})`} icon='delete_black.svg' secondary onClick={setOpenModalDeleteTeam}>Supprimer</Button>
        </Body>
      )}
    </Container>
  )
}
