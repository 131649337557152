import styled from 'styled-components'

const Styled = styled.div`
  display: block;
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
`

export default function Center (props) {
  return (
    <Styled {...props} />
  )
}
