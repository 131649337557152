import browser from './browser'

export default function sendMessageToExtension ({ message, callback, id }) {
  const extensions = [
    'hdjcojhpfphdjolncenekanikehggnah', // Chrome prod
    'oieekdlhfafimopmoennggdglekedepd', // Chrome dev
    'samy.mnasri@gmail.com' // Firefox
  ]

  try {
    if (id) {
    // eslint-disable-next-line
    browser?.runtime?.sendMessage(id, message, {}, callback)
    } else {
      extensions.map(id => {
        // eslint-disable-next-line
        return browser?.runtime?.sendMessage(id, message, {}, callback)
      })
    }
  } catch (error) {}
}
