import React from 'react'
import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'

const StyledInput = styled.input`
  display: inline-block;
  padding: ${spacing['1/2']} ${spacing[1]} ${spacing['1/2']} ${props => props.type === 'search' ? spacing[4] : spacing[1]};
  width: ${props => props.fullWidth ? '100%' : props.width ? props.width : 'auto'};
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  vertical-align: middle;

  &::placeholder {
    color: ${colors.placeholder};
  }

  ${props => (props.type === 'checkbox' || props.type === 'radio') && `
    cursor: pointer;
    vertical-align: middle;
  `}

  ${props => props.type === 'search' && `
    background-image: url("/search.svg");
    background-repeat: no-repeat;
    background-position-x: 8px;
    padding: ${spacing['1/2']} ${spacing['1/2']} ${spacing['1/2']} calc(${spacing[4]} + ${spacing[1]});
    background-position-y: center;
    background-size: 16px;
  `}
`

const StyledSelect = styled.select`
  display: inline-block;
  padding: ${spacing['1/2']} ${spacing[1]};
  height: 35.6px;
  background-color: ${colors.white};
  max-width: 100%;
  border-radius: ${spacing['1/2']};
  width: ${props => props.fullWidth ? '100%' : props.width ? props.width : 'auto'};
  border: 1px solid ${colors.lightGrey};
`

const TextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  border-radius: ${spacing['1/2']};
  border: 1px solid ${colors.lightGrey};
  padding: ${spacing['1/2']} ${spacing[1]};
`

const Input = (props) => {
  return (
    <>
      {props.label && props.type !== 'checkbox' && props.type !== 'radio' && (
        <>
          <span style={{ marginBottom: 0, display: 'inline-block', color: colors.grey, fontSize: '0.9375rem' }}>{props.label}</span>
          <br />
        </>
      )}

      {
        props.type === 'select'
          ? <StyledSelect {...props} />
          : props.label && (props.type === 'checkbox' || props.type === 'radio')
            ? (
              <label style={{ cursor: 'pointer', verticalAlign: 'middle' }}>
                <StyledInput {...props} type={props.type === 'search' ? 'text' : props.type} />
                <span style={{ marginLeft: spacing[1], verticalAlign: 'middle' }}>{props.label}</span>
              </label>
              )
            : props.type === 'textarea'
              ? <TextArea {...props} rows='6' />
              : <StyledInput {...props} />
      }
    </>
  )
}

export default Input
