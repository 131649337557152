import { useState } from 'react'
import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Icon from './Icon'
import Spacing from './Spacing'
import Tooltip from './Tooltip'

const Container = styled.div`
  width: 100%;
  display: inline-block;
`

const Header = styled.label`
  background-color: ${colors.veryLightGrey};
  width: 100%;
  padding: ${spacing['1']} ${spacing[2]};
  border-bottom: 1px solid ${colors.lightGrey};
  border: 1px solid ${colors.lightGrey};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${spacing['1/2']} ${spacing['1/2']} 0px 0px;

  ${props => (props.isCheckbox || props.isAccordion) && `
    cursor: pointer;

    &:hover {
      // border-color: ${colors.grey};
      background-color: ${colors.background};
    }
  `}

  ${props => props.isCheckbox && `
    font-weight: 400;
  `}

  ${props => (props.whiteHeader || (props.isCheckbox && !props.checked)) && `
    background-color: ${colors.white};
    // color: ${colors.grey};
  `}

  ${props => (!props.hasChildren || (props.isCheckbox && !props.checked) || (props.isAccordion && !props.accordionIsOpen)) && `
    border-radius: ${spacing['1/2']};
  `}

  ${props => (props.hasChildren && (props.isCheckbox && props.checked)) && `
    font-weight: 600;
  `}
`

const Body = styled.div`
  padding: ${props => props.removeContentPadding ? '0' : spacing[2]};
  border: 1px solid ${colors.lightGrey};
  border-top: 0px;
  border-radius: 0px 0px ${spacing['1/2']} ${spacing['1/2']};
  background-color: ${colors.white};
  /* max-height: 512px;
  max-width: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: visible; */
`

const SubHeaderContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-top: 0px;
`

export default function Card ({
  children,
  whiteHeader,
  header,
  helper,
  isCheckbox,
  radioBoxes,
  onRadioClick,
  SubHeader,
  removeContentPadding,
  isAccordion,
  onOpenAccordion,
  defaultRadioChecked,
  accordionIsOpenByDefault,
  ...props
}) {
  const [accordionIsOpen, setAccordionIsOpen] = useState(isAccordion ? accordionIsOpenByDefault : true)

  return (
    <Container>
      <Header
        isCheckbox={isCheckbox}
        hasChildren={children}
        whiteHeader={whiteHeader}
        checked={props.checked}
        isAccordion={isAccordion}
        accordionIsOpen={accordionIsOpen}
        onClick={() => {
          if (isAccordion) {
            setAccordionIsOpen(!accordionIsOpen)

            if (!accordionIsOpen) {
              onOpenAccordion && onOpenAccordion()
            }
          }
        }}
      >
        <span style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          {isCheckbox && (
            <input {...props} type='checkbox' style={{ marginRight: spacing[2], verticalAlign: 'middle', cursor: 'pointer' }} />
          )}

          {isAccordion && (
            <>
              {
                accordionIsOpen
                  ? <Icon icon='/expand_more_black.svg' />
                  : <Icon icon='/chevron_right.svg' />
              }

              <Spacing x={1} />
            </>

          )}

          <span>{header}</span>
        </span>

        {helper && (
          <span onClick={e => { e.preventDefault(); e.stopPropagation() }} style={{ flexShrink: '0', display: 'inline-flex', cursor: 'pointer', marginLeft: spacing['1/2'] }}>
            <Tooltip text={helper}>
              <Icon icon='help_outline_grey.svg' />
            </Tooltip>
          </span>
        )}
      </Header>

      {SubHeader && accordionIsOpen && (
        <SubHeaderContainer>
          <SubHeader />
        </SubHeaderContainer>
      )}

      {children && accordionIsOpen && (!isCheckbox || props.checked) &&
        <Body removeContentPadding={removeContentPadding}>
          {children}
        </Body>}
    </Container>
  )
}
