import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Button from './Button'

const Container = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  padding: ${spacing['1/2']} ${spacing['1']};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => props.margin && `
    margin: ${props.margin};
  `}

  ${props => props.isClickable && `
    cursor: pointer;
    &:hover > * {
      text-decoration: underline;
    }
  `}
`

export default function ListItem ({
  children,
  margin,
  isClickable,
  callToAction,
  callToActionType,
  ...props
}) {
  return (
    <Container
      margin={margin}
      isClickable={isClickable}
      {...props}
    >
      <span>
        {children}
      </span>

      {callToAction && (
        <Button
          small
          smallText
          smallHeight
          secondary={callToActionType === 'secondary'}
          primaryLight={!callToActionType || callToActionType === 'primaryLight'}
          style={{
            marginTop: spacing['1/2'],
            marginBottom: spacing['1/2']
          }}
        >
          {callToAction}
        </Button>
      )}
    </Container>
  )
}
