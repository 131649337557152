import { useContext, useEffect, useState } from 'react'
import supabase from '../supabase'
import Button from './Button'
import Team from './Team'
import Card from './Card'
import Modal from './Modal'
import Input from './Input'
import Spacing from './Spacing'
import UserContext from '../contexts/UserContext'
import OrganizationContext from '../contexts/OrganizationContext'

let newTeamName

export default function Organization () {
  const [members, setMembers] = useState()
  const [teams, setTeams] = useState()
  const { user, setOnboardingUpdatedAt } = useContext(UserContext)
  const { getOrganizationData } = useContext(OrganizationContext)
  const [organisationId, setOrganizationId] = useState()
  const [openModal, setOpenModal] = useState(false)

  const getMembers = async () => {
    if (user?.id) {
      const membersData = await getOrganizationData()
      setMembers(membersData?.users.sort((a, b) => a.email.localeCompare(b.email)))
      setTeams(membersData?.teams.sort((a, b) => a.name.localeCompare(b.name)))
      setOrganizationId(membersData?.id)
    }
  }

  useEffect(() => {
    getMembers()
  }, [])

  const handleNewTeam = async () => {
    setOpenModal(false)

    await supabase
      .from('teams')
      .insert({
        name: newTeamName,
        organization_id: organisationId
      })

    getMembers()
    setOnboardingUpdatedAt && setOnboardingUpdatedAt(new Date())
  }

  return (
    <>
      <Modal open={openModal} onClose={setOpenModal} hideHeader doublePadding onConfirm={handleNewTeam}>
        <Input type='text' label="Nom de l'équipe à créer" width='100%' placeholder='Exemple : Support client' onChange={e => { newTeamName = e.target.value }} />
      </Modal>

      <Card header='🤝 Équipes de mon organisation' helper='Définissez des équipes pour déterminer quels membres doivent connaître telle ou telle documentation'>
        {!teams?.length && (
          <>
            <div>Aucune équipe n'a été ajoutée</div>
            <Spacing y={2} block />
          </>
        )}

        {teams?.map(team => <Team teamMembers={team.users_to_teams.map(el => el.users).sort((a, b) => a?.email.localeCompare(b?.email))} organizationMembers={members} onUpdated={getMembers} key={team.id} team={team} />)}

        {/* <Spacing block y='1' /> */}
        <Button icon='add_white.svg' width='100%' primary onClick={setOpenModal}>Nouvelle équipe</Button>
      </Card>
    </>
  )
}
