import { useContext, useEffect, useState } from 'react'
import createDefaultCondition from '../createDefaultCondition'
import supabase from '../supabase'
import Spacing from './Spacing'
import Input from './Input'
import Button from './Button'
import Card from './Card'
import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import UserContext from '../contexts/UserContext'
import Alert from './Alert'
import Link from './Link'
import Modal from './Modal'
import EditorJS from '@editorjs/editorjs'
import editorSettings from '../editorSettings'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import sendMessageToExtension from '../sendMessageToExtension'
import Icon from './Icon'
import OrganizationContext from '../contexts/OrganizationContext'
import documentStatusEnum from '../enums/documentStatusEnum'
import Badge from './Badge'
import TagsSelector from './TagsSelector'
import _getDocuments from '../getDocuments'
import { Graph } from 'react-d3-graph'
import urlHelpers from '../urlHelpers'
import ListItem from './ListItem'
import EditDocumentConditionsCard from './EditDocumentConditionsCard'
import validationTypeEnum from '../enums/validationTypeEnum'

const TeamContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  display: flex;
  align-items: center;
  margin-top: ${spacing[2]};
  padding-left: ${spacing[1]};
  justify-content: space-between;
  max-width: 100%;
`

const ValidatedContainer = styled.div`
  border-radius: ${spacing['1/2']};
  border: 1px solid ${colors.green};
  padding: ${spacing[1]} ${spacing[2]};
  text-align: center;
  
  ${props => props.length && `
    cursor: pointer;
    border-color: ${colors.primary};

    &:hover {
      border-color: ${colors.primary};
    }
  `}
`

const preventExitWithoutSaving = (e) => {
  e.preventDefault()
  e.returnValue = ''
}

export default function EditDocument () {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const { user, setOnboardingUpdatedAt } = useContext(UserContext)
  const { getOrganizationData, organizationData } = useContext(OrganizationContext)
  const [conditions, setConditions] = useState(createDefaultCondition(false, true, { from: searchParams.get('from') }))
  const [openModal, setOpenModal] = useState(false)
  const [openModalNotValidatedBy, setOpenModalNotValidatedBy] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalLinkedDocuments, setOpenModalLinkedDocuments] = useState(false)
  const [name, setName] = useState()
  const [url, setUrl] = useState()
  const [editor, setEditor] = useState()
  const [tag, setTag] = useState()
  const [tags, setTags] = useState()
  const [documents, setDocuments] = useState()
  const [linkedDocuments, setLinkedDocuments] = useState([])
  const [allRelatedDocuments, setAllRelatedDocuments] = useState([])
  const linkedDocumentsIds = linkedDocuments.map(el => el.id)
  const [initialTags, setInitialTags] = useState()
  const [lastNodeClicked, setLastNodeClicked] = useState()
  const [createdTags, setCreatedTags] = useState()
  const [contentTab, setContentTab] = useState(0)
  const [content, setContent] = useState()
  const [document, setDocument] = useState()
  const [updateFrequency, setUpdateFrequency] = useState(3)
  const [documentDataHasLoaded, setDocumentDataHasLoaded] = useState(false)
  const [quickQuizQuestion, setQuickQuizQuestion] = useState()
  const [quickQuizOption1, setQuickQuizOption1] = useState()
  const [quickQuizOption2, setQuickQuizOption2] = useState()
  const [quickQuizOption3, setQuickQuizOption3] = useState()
  const [ownerId, setOwnerId] = useState()
  const [quickQuizOption4, setQuickQuizOption4] = useState()
  const [quickQuizAnswer, setQuickQuizAnswer] = useState(1)
  const [selectedTeam, setSelectedTeam] = useState()
  // const [teamsChecked, setTeamsChecked] = useState(false)
  const teamsChecked = true
  const [documentTeams, setDocumentTeams] = useState([])
  const [notValidatedBy, setNotValidatedBy] = useState([])
  const [validationMethod, setValidationMethod] = useState(validationTypeEnum.OPEN)
  const navigate = useNavigate()
  const [quiz, setQuiz] = useState()
  const [teams, setTeams] = useState([])
  const teamsNotAdded = teams?.filter(team => !documentTeams.map(el => el.id).includes(team.id))
  let initialTeams = []

  const nodes = Object.entries(allRelatedDocuments.reduce((acc, current) => {
    acc[current.source_document_id] = {
      id: current.source_document_id,
      name: current.source_document_name
    }

    acc[current.target_document_id] = {
      id: current.target_document_id,
      name: current.target_document_name
    }

    return acc
  }, {})).map(([key, document]) => ({ id: document.id, label: document.name })) // [{ id: 'Harry' }, { id: 'Sally' }, { id: 'Alice' }],

  const links = allRelatedDocuments.map(relation => ({
    source: relation.source_document_id,
    target: relation.target_document_id
  }))

  const graphData = {
    nodes,
    links
  }

  const getRelatedDocuments = async () => {
    const { data } = await supabase
      .from('related_documents')
      .select(`
        *,
        source_document (
          *
        ),
        target_document (
          *
        )
      `)
      .or(`source_document.eq.${id},target_document.eq.${id}`)

    const { data: allRelatedData } = await supabase
      .from('related_documents_from_organization')
      .select(`
        *
      `)
      .eq('organization_id', organizationData.id)

    setLinkedDocuments(data.map(el => el.source_document.id === parseInt(id, 10) ? el.target_document : el.source_document).filter(el => el.id !== parseInt(id, 10)))
    setAllRelatedDocuments(allRelatedData)
  }

  const getDocuments = async () => {
    const { data } = await _getDocuments(user)
    setDocuments(data)
  }

  useEffect(() => { // Get documents
    getDocuments()
  }, [])

  const getNotValidatedBy = async () => {
    const { data } = await supabase
      .from('documents_to_validate')
      .select()
      .eq('document_id', id)

    setNotValidatedBy(data)
  }

  const getDocument = async () => {
    const { data } = await supabase
      .from('documents')
      .select(`
        *,
        documents_to_tags (
          tag_id (
            *
          )
        ),
        documents_to_teams (
          team_id (
            *
          )
        )
      `)
      .eq('id', id)

    setName(data[0].name)
    setUrl(data[0].url)
    setOwnerId(data[0].user_id)
    setUpdateFrequency(data[0].update_frequency)
    setDocument(data[0])
    // setLinkedDocuments(data[0].related_documents)
    setTags(data[0].documents_to_tags.map(el => el.tag_id))
    setInitialTags(data[0].documents_to_tags.map(el => el.tag_id))
    setContentTab(data[0].is_external ? 1 : 0)
    setContent(data[0].content)
    setDocumentTeams(data[0].documents_to_teams.map(el => el.team_id).sort((a, b) => a.name.localeCompare(b.name)))
    initialTeams = data[0].documents_to_teams.map(el => el.team_id)
    setValidationMethod(data[0].validation_type)

    if (data[0].validation_type === validationTypeEnum.QUICK_QUIZ) {
      setQuickQuizAnswer(data[0].quick_quiz?.answer)
      setQuickQuizQuestion(data[0].quick_quiz?.question)
      setQuickQuizOption1(data[0].quick_quiz?.options[0])
      setQuickQuizOption2(data[0].quick_quiz?.options[1])
      setQuickQuizOption3(data[0].quick_quiz?.options[2])
      setQuickQuizOption4(data[0].quick_quiz?.options[3])
    }

    if (data[0].conditions) {
      setConditions(data[0].conditions)
    }

    if (data[0].validation_type === validationTypeEnum.QUIZ) {
      setQuiz(data[0].quiz)
    }

    if (data[0].documents_to_teams.length) {
      // setTeamsChecked(true)
    }
  }

  const loadEditor = () => {
    setEditor(new EditorJS({
      ...editorSettings({ documentId: id }),
      data: content,
      placeholder: 'Écrire le contenu de la documentation ici',
      onChange: api => {
        api.saver.save().then((outputData) => {
          setContent(outputData)
        })
      }
    }))
  }

  useEffect(() => { // Set document data loaded
    if (typeof content !== 'undefined') {
      setDocumentDataHasLoaded(true)
    }
  }, [content])

  useEffect(() => { // Get document from database
    if (id) {
      getDocument()
      getNotValidatedBy()
    }
  }, [id])

  useEffect(() => { // Get related documents
    if (id && organizationData?.id) {
      getRelatedDocuments()
    }
  }, [id, organizationData])

  useEffect(() => { // Load editor
    if (contentTab !== 0) {
      setEditor(null)
    } else if (!editor && contentTab === 0 && (documentDataHasLoaded || !id)) {
      // Don't remove the "const editor = " > it won't work without it
      loadEditor()
    }
  }, [contentTab, documentDataHasLoaded, id])

  useEffect(() => { // Get teams
    const getTeams = async () => {
      if (user?.id) {
        const { data } = await supabase
          .from('users')
          .select(`
              organization_id (
                teams (
                  *
                )
              )
            `)
          .eq('id', user?.id)

        setTeams(data[0].organization_id?.teams?.sort((a, b) => a.name.localeCompare(b.name)))
      }
    }

    getTeams()
  }, [])

  useEffect(() => { // Prevent from closing without saving
    window.addEventListener('beforeunload', preventExitWithoutSaving)
    return () => { window.removeEventListener('beforeunload', preventExitWithoutSaving) }
  }, [])

  const handleCreateTag = async () => {
    const { data: tagData } = await supabase
      .from('tags')
      .insert([
        { tag }
      ])

    // getAvailableTags()
    setCreatedTags(createdTags ? [...createdTags, ...tagData] : tagData)
    setTags(tags ? [...tags, ...tagData] : tagData)
    setTag('')
  }

  const handleSave = async event => {
    event.preventDefault()

    if (!name) {
      setOpenModal('Merci de renseigner le nom de cette documentation')
    } else if (!url && contentTab === 1) {
      setOpenModal('Merci de renseigner le lien URL du contenu de la documentation')
    } else if (contentTab === 1 && !url.match(/[^.]+[.][^.]+/)) {
      setOpenModal('Le format du lien URL du contenu de la documentation est incorrect')
    } else if (validationMethod === validationTypeEnum.QUICK_QUIZ && !quickQuizQuestion) {
      setOpenModal('Merci de renseigner le titre de la question du quizz rapide')
    } else if (validationMethod === validationTypeEnum.QUICK_QUIZ && (quickQuizAnswer === 3 && !quickQuizOption3)) {
      setOpenModal('La réponse du quizz rapide correspond à une option vide')
    } else if (validationMethod === validationTypeEnum.QUICK_QUIZ && (quickQuizAnswer === 4 && !quickQuizOption4)) {
      setOpenModal('La réponse du quizz rapide correspond à une option vide')
    } else if (validationMethod === validationTypeEnum.QUICK_QUIZ && (!quickQuizOption1 || !quickQuizOption2)) {
      setOpenModal('Merci de renseigner au moins 2 réponses pour le quizz rapide')
    } else if (validationMethod === validationTypeEnum.QUIZ && !quiz) {
      setOpenModal('Merci de renseigner le lien URL du quizz Typeform')
    } else if (validationMethod === validationTypeEnum.QUIZ && quiz && !quiz.includes('typeform.com')) {
      setOpenModal("Le lien URL du quizz ne correspond pas à celui d'un Typeform")
    } else {
      const dataToSave = {
        name,
        url,
        content,
        validation_type: validationMethod,
        update_frequency: updateFrequency,
        updated_at: new Date(),
        status: (contentTab === 1 ? url : content) ? null : documentStatusEnum.TO_CREATE.id,
        user_id: ownerId || user?.id,
        quick_quiz: validationMethod === validationTypeEnum.QUICK_QUIZ
          ? { question: quickQuizQuestion, answer: quickQuizAnswer, options: [quickQuizOption1, quickQuizOption2, quickQuizOption3, quickQuizOption4] }
          : null,
        is_external: contentTab === 1,
        conditions,
        quiz: validationMethod === validationTypeEnum.QUIZ
          ? quiz
          : null
      }

      if (id) {
        dataToSave.id = id
      }

      const { data } = await supabase
        .from('documents')
        .upsert(dataToSave)

      const teamsToRemove = !teamsChecked ? initialTeams : initialTeams?.filter(initial => !documentTeams.map(el => el.id).includes(initial.id))
      const teamsToAdd = teamsChecked ? documentTeams?.filter(el => !initialTeams.map(initial => initial.id).includes(el.id)) : []

      await supabase
        .from('documents_to_teams')
        .upsert(teamsToAdd.map(el => ({ team_id: el.id, document_id: data[0].id })))

      await supabase
        .from('documents_to_teams')
        .delete()
        .eq('document_id', data[0].id)
        .in('team_id', teamsToRemove.map(el => el.id))

      await supabase
        .from('users_to_documents')
        .upsert({
          user_id: user.id,
          document_id: data[0].id,
          viewed_at: new Date(),
          has_opened: true
        })

      await supabase
        .from('documents_to_tags')
        .upsert((tags || []).map(tag => ({
          tag_id: tag.id,
          document_id: data[0].id
        })))

      const selectedTagsIds = (tags || []).map(el => el.id)
      const tagsToRemove = (initialTags || []).filter(el => !selectedTagsIds.includes(el.id))

      if (tagsToRemove.length) {
        await supabase
          .from('documents_to_tags')
          .delete()
          .eq('document_id', data[0].id)
          .in('tag_id', tagsToRemove.map(el => el.id))
      }

      const linkedDocumentsIdsToAdd = linkedDocuments.map(el => el.id)
      const linkedDocumentsIdsToRemove = []

      if (linkedDocumentsIdsToAdd.length) {
        await supabase
          .from('related_documents')
          .upsert(linkedDocumentsIdsToAdd.map(el => ({
            source_document: data[0].id,
            target_document: el
          })))
      }

      if (linkedDocumentsIdsToRemove.length) {
        // await supabase
        //   .from('related_documents')
        //   .delete()
        //   .eq('document_id', data[0].id)
        //   .in('tag_id', tagsToRemove.map(el => el.id))
      }

      sendMessageToExtension({ message: 'getDocuments' })
      window.scrollTo(0, 0)
      setOnboardingUpdatedAt && setOnboardingUpdatedAt(new Date())
      navigate('/app')
    }
  }

  const handleDelete = async event => {
    await supabase
      .from('documents')
      .delete()
      .match({ id })

    sendMessageToExtension({ message: 'getDocuments' })
    window.scrollTo(0, 0)
    navigate('/app')
  }

  const handleAddTeam = async () => {
    // await supabase
    //   .from('documents_to_teams')
    //   .insert({
    //     document_id: id,
    //     team_id: selectedTeam
    //   })

    // getDocument()
    if (selectedTeam) {
      const newDocumentTeams = JSON.parse(JSON.stringify([...documentTeams, teamsNotAdded?.filter(el => el.id === selectedTeam)[0]]))
      setDocumentTeams(newDocumentTeams.sort((a, b) => a.name.localeCompare(b.name)))
    } else {
      setOpenModal('Merci de sélectionner une équipe (vous pouvez en créer de nouvelles en allant dans les paramètres de votre organisation)')
    }
  }

  const handleRemoveTeam = async (teamId) => {
    // await supabase
    //   .from('documents_to_teams')
    //   .delete()
    //   .match({
    //     document_id: id,
    //     team_id: teamId
    //   })

    // getDocument()

    const newDocumentTeams = JSON.parse(JSON.stringify(documentTeams?.filter(el => el.id !== teamId)))
    setDocumentTeams(newDocumentTeams)
  }

  useEffect(() => { // Get organization data
    if (user?.id) {
      getOrganizationData(true)
    }
  }, [user])

  return (
    <form id='graph-container'>
      <Modal open={openModal} onClose={setOpenModal} title='Enregistrement impossible'>
        {openModal}
      </Modal>

      <Modal open={openModalLinkedDocuments} onClose={setOpenModalLinkedDocuments} title='Sélectionner une documentation à lier'>
        {documents?.filter(el => !linkedDocumentsIds.includes(el.id) && el.id !== document?.id).map((document, index) => (
          <div
            key={document.id}
          >
            {index !== 0 && (
              <Spacing y={2} />
            )}

            <ListItem
              onClick={() => setLinkedDocuments([...linkedDocuments, document])}
              isClickable
              callToAction='Ajouter'
            >
              {document.name}
            </ListItem>
          </div>
        ))}
      </Modal>

      <Modal open={openModalNotValidatedBy} onClose={setOpenModalNotValidatedBy} title="Collègues qui n'ont pas validé cette documentation">
        {notValidatedBy.map((colleague, index) => (
          <div key={colleague.user_email}>
            {index !== 0 && (
              <Spacing y={2} block />
            )}

            <div style={{ border: '1px solid ' + colors.lightGrey, textOverflow: 'ellipsis', overflow: 'hidden', borderRadius: spacing['1/2'], padding: `${spacing[1]} ${spacing[2]}` }}>{colleague.user_email}</div>
          </div>
        ))}
      </Modal>

      <Modal open={openModalDelete} onClose={setOpenModalDelete} hideHeader onConfirm={handleDelete} doublePadding confirmButtonText='Supprimer'>
        Supprimer définitivement cette documentation ?
      </Modal>

      {!user?.organization_id && id && (
        <>
          <Spacing block y={2} />

          <Alert>
            Pour partager cette documentation avec quelqu'un, merci de l'inviter dans votre organisation depuis vos paramètres
          </Alert>
        </>
      )}

      {id && user?.organization_id && (
        <>
          <Spacing block y={2} />

          <ValidatedContainer onClick={() => { if (notValidatedBy?.length) { setOpenModalNotValidatedBy(true) } }} length={notValidatedBy?.length}>
            {/* <b style={{ color: colors.green }}>X</b> collègues l'ont validé
            <span style={{ margin: '0 ' + spacing[2] }}>/</span> */}
            {
              notValidatedBy?.length
                ? (
                  <span style={{ color: colors.primary }}>
                    <span style={{ verticalAlign: 'middle' }}>
                      <b style={{ marginRight: spacing['1/2'] }}>
                        {notValidatedBy?.length || 0}
                      </b>

                      {' '}
                      collègue{notValidatedBy?.length === 1 ? '' : 's'}
                      {' '}
                      n'{notValidatedBy?.length === 1 ? 'a' : 'ont'}
                      {' '}
                      pas validé cette documentation
                    </span>

                    <Spacing x={2} />
                    <Icon style={{ verticalAlign: 'middle' }} icon='/open_in_full_red.svg' />
                  </span>
                  )
                : (
                  <span style={{ color: colors.green }}>
                    Cette documentation a été validée par tous vos collègues !

                    <Spacing x={2} />
                    <Icon style={{ verticalAlign: 'middle' }} icon='/check_circle_green.svg' />
                  </span>
                  )
            }

          </ValidatedContainer>
        </>
      )}

      {!id && (
        <div>
          <Spacing y={2} />

          <Button
            icon='/notion_logo.svg'
            width='100%'
            onClick={e => {
              e.preventDefault()
              window.removeEventListener('beforeunload', preventExitWithoutSaving)

              window.location.href = (
                'https://api.notion.com/v1/oauth/authorize?client_id=8a27d180-7298-408c-b648-4eefc576e49d&response_type=code&owner=user&redirect_uri=' +
                encodeURIComponent(
                  (
                    window.location.origin.includes('localhost')
                      ? 'http://localhost:3000/'
                      : 'https://www.documir.com/'
                  ) +
                  'api/notion'
                ) + '&state=' + encodeURIComponent(user.id)
              )
            }}
            // href={
            // }
            secondary
          >
            Importer depuis Notion
          </Button>
        </div>
      )}

      <Spacing block y={2} />

      <Card header='📋 Nom de la documentation *' isAccordion accordionIsOpenByDefault>
        <Input
          onChange={e => { setName(e.target.value) }}
          value={name || ''}
          type='text'
          placeholder='Exemple : Comment faire pour ...'
          // label='Nom de la documentation *'
          fullWidth
        />
      </Card>

      <Spacing block y={2} />

      <Card
        isAccordion
        accordionIsOpenByDefault
        header='✒️ Contenu'
        onOpenAccordion={loadEditor}
        SubHeader={() => (
          <div style={{ padding: `${spacing[1]} ${spacing[2]}` }}>
            <label
              onClick={() => setContentTab(0)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <input
                onChange={() => {}}
                checked={contentTab === 0}
                name='cardHeader'
                // value={radioBoxes[0]}
                type='radio'
              />

              <Spacing x={1} />
              Rédigé sur Documir
            </label>

            <Spacing y={1} />

            <label
              onClick={() => setContentTab(1)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <input
                onChange={() => {}}
                checked={contentTab === 1}
                name='cardHeader'
              // value={radioBoxes[1]}
                type='radio'
              />

              <Spacing x={1} />
              Rédigé ailleurs
            </label>
          </div>
        )}
      >
        {
          contentTab === 0
            ? (
              <div>
                {/* <Input label='Contenu de la documentation' type='textarea' defaultValue={content} onChange={e => setContent(e.target.value)} placeholder='Écrire ici le contenu de la documentation' /> */}
                <div id='editorjs' />
              </div>
              )
            : <Input onChange={e => setUrl(e.target.value)} defaultValue={url} type='url' label='Lien URL du contenu de la documentation *' placeholder='Exemple : https://documir.notion.site/Comment-utiliser-Documir-aceb43d31ba341a9ab211a3a268e852b' fullWidth />
        }

      </Card>

      <Spacing block y={2} />

      <EditDocumentConditionsCard
        accordionIsOpenByDefault={!id && searchParams.get('from')}
        from={searchParams.get('from')}
        conditions={conditions}
        setConditions={setConditions}
      />

      <Spacing block y={2} />

      <div>
        <Card isAccordion header='✅ Méthode de validation' helper='La documentation sera mise en avant tant qu’elle n’aura pas été validée'>
          <Input label='Comment valider la connaissance de cette documentation ?' fullWidth value={validationMethod} type='select' onChange={e => setValidationMethod(e.target.value)}>
            <option value={validationTypeEnum.OPEN}>A l'ouverture de la documentation</option>
            <option value={validationTypeEnum.QUICK_QUIZ}>Via un quizz rapide (QCM)</option>
            <option value={validationTypeEnum.QUIZ}>Via un quizz Typeform</option>
            <option value={validationTypeEnum.NONE}>Aucune validation requise</option>
          </Input>

          {validationMethod === validationTypeEnum.QUIZ && (
            <div>
              <Spacing block y={2} />
              <Input fullWidth defaultValue={quiz} onChange={e => setQuiz(e.target.value)} label='Lien URL du quizz Typeform *' placeholder='Exemple : https://documir.typeform.com/to/qO7SaVxS' />
              <Spacing block y={2} />
              <Alert>
                Merci de lire <Link underlined target='_blank' href='https://samymnasri.notion.site/Comment-utiliser-Documir-aceb43d31ba341a9ab211a3a268e852b#5544ea3f16cf40aea50999b79de8eb57' rel='noreferrer'>ces instructions</Link> pour intégrer Typeform à Documir
              </Alert>
            </div>
          )}

          {validationMethod === validationTypeEnum.QUICK_QUIZ && (
            <div>
              <Spacing block y={2} />
              <Input defaultValue={quickQuizQuestion} onChange={e => setQuickQuizQuestion(e.target.value)} fullWidth label='Titre de la question à choix multiple *' placeholder='Exemple : Laquelle de ces 4 affirmations est correcte ?' />
              <Spacing block y={2} />
              <Input defaultValue={quickQuizOption1} onChange={e => setQuickQuizOption1(e.target.value)} fullWidth label='Choix n°1 *' placeholder='1ère réponse' />
              <Spacing block y={1} />
              <Input defaultValue={quickQuizOption2} onChange={e => setQuickQuizOption2(e.target.value)} fullWidth label='Choix n°2 *' placeholder='2ème réponse' />
              <Spacing block y={1} />
              <Input defaultValue={quickQuizOption3} onChange={e => setQuickQuizOption3(e.target.value)} fullWidth label='Choix n°3' placeholder='3ème réponse' />
              <Spacing block y={1} />
              <Input defaultValue={quickQuizOption4} onChange={e => setQuickQuizOption4(e.target.value)} fullWidth label='Choix n°4' placeholder='4ème réponse' />
              <Spacing block y={2} />

              <Input defaultValue={quickQuizAnswer} fullWidth onChange={e => setQuickQuizAnswer(parseInt(e.target.value, 10))} type='select' label='Quelle est la bonne réponse ?'>
                <option value={1}>Choix n°1</option>
                <option value={2}>Choix n°2</option>
                <option value={3}>Choix n°3</option>
                <option value={4}>Choix n°4</option>
              </Input>
            </div>
          )}
        </Card>

      </div>

      {user?.organization_id &&
        (
          <>
            <Spacing block y={2} />

            <div>
              <Card
                // onChange={e => setTeamsChecked(e.target.checked)}
                // checked={teamsChecked}
                isAccordion
                header='🤝 Équipes concernées'
                helper='La documentation sera mise en avant pour les membres de ces équipes (ou pour tout le monde si aucune équipe n’est ajoutée)'
              >
                <div style={{ display: 'flex', maxWidth: '100%' }}>
                  <Input style={{ flex: '1 1 0px', width: '100%' }} type='select' onChange={e => setSelectedTeam(parseInt(e.target.value, 10))}>
                    <option>-- Choisir une équipe à ajouter --</option>

                    {teamsNotAdded && teamsNotAdded.map(team => (
                      <option value={team.id} key={team.id}>{team.name}</option>
                    ))}
                  </Input>

                  <Spacing x={1} />
                  <Button icon='add_primary.svg' primaryLight onClick={handleAddTeam}>Ajouter</Button>
                </div>

                {documentTeams && documentTeams.map(team => (
                  <TeamContainer key={team.id}>
                    <div>
                      {team.name}
                    </div>

                    <div>
                      <Button small icon='highlight_off_black.svg' onClick={() => handleRemoveTeam(team.id)} />
                    </div>
                  </TeamContainer>
                ))}

                {!documentTeams?.length && (
                  <>
                    <Spacing block y={2} />

                    <Alert>
                      Si aucune équipe n'est ajoutée, la documentation sera mise en avant pour tous les membres de l'organisation. Vous pouvez
                      {' '}

                      <Link underlined target='_blank' href={urlHelpers.buildUrl(urlHelpers.paths.ORGANIZATION)} rel='noreferrer'>
                        créer une équipe dans les paramètres de l'organisation
                      </Link>

                      .
                    </Alert>
                  </>
                )}
              </Card>

            </div>
          </>
        )}

      {user?.organization_id && (
        <>
          <Spacing y={2} />

          <Card
            isAccordion
            header='👑 Responsable'
            helper='Le responsable est la personne en charge de compléter et mettre à jour la documentation quand nécessaire'
          >
            <Input onChange={e => setOwnerId(e.target.value)} value={ownerId || user?.id} type='select' width='100%'>
              {organizationData?.users.map(colleague => (
                <option value={colleague.id} key={colleague.id}>{colleague.email}</option>
              ))}
            </Input>
          </Card>
        </>
      )}

      <Spacing y={2} />

      <Card
        isAccordion
        header='⏳ Fréquence de mise à jour'
        helper='Le responsable de la documentation sera notifié à cette fréquence pour mettre à jour cette documentation si nécessaire'
      >
        <Input onChange={e => setUpdateFrequency(e.target.value)} value={updateFrequency || ''} type='select' width='100%'>
          <option value={null}>Ne jamais la mettre à jour</option>
          <option value={1}>Tous les mois</option>

          {[...Array(36)].map((value, index) => (
            <option key={index} value={index + 1}>Tous les {index + 1} mois</option>
          ))}
        </Input>

        {document?.created_at && (
          <>
            <Spacing y={2} />

            <div style={{ color: colors.grey }}>
              Date de dernière mise à jour : {new Date(document?.updated_at || document?.created_at).toLocaleDateString('fr')}
            </div>
          </>
        )}
      </Card>

      <Spacing y={2} />

      <Card
        isAccordion
        header='🏷️ Tags'
        helper='Les tags vous permettent de catégoriser et chercher plus facilement des documentations en fonction de vos besoins'
      >
        <TagsSelector
          selectedTags={tags}
          setTags={setTags}
          tag={tag}
          setSearch={setTag}
          createdTags={createdTags}
        />

        {tag && (
          <>
            <Spacing y={2} />

            <Button onClick={() => handleCreateTag()} width='100%' secondary>
              Créer le tag
              <Spacing x={1} />

              <Badge isTag>{tag}</Badge>
            </Button>
          </>
        )}
      </Card>

      <Spacing y={2} />

      <Card
        isAccordion
        header="🔗 Liens avec d'autres documentations"
        helper='Liez les documentations entre elles pour plus facilement retrouver leurs dépendances et voir leur diagramme de dépendance'
      >
        <Button primaryLight onClick={() => setOpenModalLinkedDocuments(true)} width='100%'>
          Lier à une autre documentation
        </Button>

        {linkedDocuments?.map((document, index) => (
          <div
            key={document.id}
          >
            <Spacing y={2} />

            <ListItem
              isClickable
              callToAction='Retirer'
              callToActionType='secondary'
              onClick={() => setLinkedDocuments(linkedDocuments.filter(el => el.id !== document.id))}
            >
              {document.name}
            </ListItem>
          </div>
        ))}

        <Spacing y={2} />

        <Card removeContentPadding header='Diagramme de dépendance'>
          <div style={{ width: '100%', maxWidth: '100%' }}>
            {allRelatedDocuments.length
              ? (
                <Graph
                  id='graph-id' // id is mandatory
                  data={graphData}
                  onClickNode={(id, node) => setLastNodeClicked(node)}
                  config={{
                    width: (window?.document?.getElementById('graph-container')?.offsetWidth - (16 * 2) - 4) || 445,
                    height: 300,
                    automaticRearrangeAfterDropNode: true, // Ne fonctionne pas en même temps que nodeHighlightBehavior
                    // nodeHighlightBehavior: true, // Ne fonctionne pas en même temps que automaticRearrangeAfterDropNode
                    // linkHighlightBehavior: true,
                    node: {
                      color: colors.blue,
                      highlightStrokeColor: 'blue',
                      size: 120,
                      labelProperty: 'label'
                    },
                    link: {
                      highlightColor: colors.blue
                    },
                    d3: {
                      gravity: -200
                    }
                  }}
                />
                )
              : (
                <div style={{ padding: spacing[2] }}>
                  Ce diagramme sera disponible une fois que vous aurez lié des documentations (et enregistré ces modifications).
                </div>
                )}

            {lastNodeClicked && (
              <ListItem
                margin={spacing[2]}
                isClickable
                callToAction='Ouvrir'
                onClick={() => { window.location.href = urlHelpers.buildUrl(urlHelpers.paths.EditDocument(lastNodeClicked.id)) }}
              >
                {lastNodeClicked.label}
              </ListItem>
            )}
          </div>
        </Card>
      </Card>

      <Spacing block y={2} />
      <Button width='100%' primary icon='save_white.svg' onClick={handleSave}>Enregistrer</Button>

      {id && (
        <>
          <Spacing block y={2} />
          <Button width='100%' secondary onClick={setOpenModalDelete} icon='delete_black.svg'>Supprimer</Button>
        </>
      )}
    </form>
  )
}
