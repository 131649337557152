import Header from '@editorjs/header'
import ImageTool from '@editorjs/image'
import Embed from '@editorjs/embed'
import LinkTool from '@editorjs/link'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import NestedList from '@editorjs/nested-list'
import Underline from '@editorjs/underline'
import AttachesTool from '@editorjs/attaches'
import ColorPlugin from 'editorjs-text-color-plugin'
import axios from 'axios'

const uploadOnAws = async ({ file, documentId }) => {
  const { data: { url, fields } } = await axios.get(
    '/api/upload-file',
    {
      params: {
        fileType: file.type,
        fileName: `${documentId}-${new Date().toISOString()}`
      }
    }
  )

  const formData = new window.FormData()

  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value)
  })

  await axios({
    method: 'post',
    url,
    data: formData
  })

  return {
    success: 1,
    file: {
      url: url + '/' + fields.key
    }
  }
}

export default ({ documentId }) => ({
  holder: 'editorjs',
  tools: {
    header: Header,
    embed: Embed,
    list: {
      class: NestedList,
      inlineToolbar: true
    },
    Color: {
      class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
      config: {
        colorCollections: ['#EC7878', '#9C27B0', '#673AB7', '#3F51B5', '#0070FF', '#03A9F4', '#00BCD4', '#4CAF50', '#8BC34A', '#CDDC39', '#FFF'],
        defaultColor: '#FF1300',
        type: 'text'
      }
    },
    Marker: {
      class: ColorPlugin, // if load from CDN, please try: window.ColorPlugin
      config: {
        defaultColor: '#FFBF00',
        type: 'marker'
      }
    },
    attaches: {
      class: AttachesTool,
      config: {
        errorMessage: 'Une erreur est survenue',
        buttonText: 'Sélectionner un fichier',
        uploader: {
          uploadByFile: async file => {
            return uploadOnAws({ file, documentId })
          }
        }
      }
    },
    inlineCode: {
      class: InlineCode
    },
    delimiter: Delimiter,
    underline: Underline,
    linkTool: {
      class: LinkTool,
      config: {
        endpoint: '/api/fetch-url'
      }
    },
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile: async file => {
            return uploadOnAws({ file, documentId })
          }
        },
        buttonContent: 'Sélectionner une image'
      }
    }
  },
  minHeight: 0,
  i18n: {
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            'Click to tune': 'Cliquer pour modifier le bloc',
            'or drag to move': 'ou glisser pour déplacer'
          }
        },
        inlineToolbar: {
          converter: {
            'Convert to': 'Convertir en'
          }
        },
        toolbar: {
          toolbox: {
            Add: 'Ajouter'
          }
        }
      },
      toolNames: {
        Text: 'Texte',
        Heading: 'Titre',
        List: 'Liste',
        Delimiter: 'Délimiteur',
        Attachment: 'Fichier',
        Link: 'Lien',
        Marker: 'Marqueur',
        Bold: 'Gras',
        Color: 'Couleur',
        Underline: 'Souligner',
        InlineCode: 'Code'
      },
      tools: {
        link: {
          'Add a link': 'Ajouter un lien'
        },
        attaches: {
          'File title': 'Titre du fichier'
        },
        stub: {
          'The block can not be displayed correctly.': 'Ce bloc ne peut pas être affiché'
        },
        list: {
          Unordered: 'Liste à puces',
          Ordered: 'Liste numérotée'
        },
        image: {
          'With background': 'Arrière plan',
          'Stretch image': 'Élargir',
          'With border': 'Bords'
        }
      },
      blockTunes: {
        delete: {
          Delete: 'Supprimer'
        },
        moveUp: {
          'Move up': 'Déplacer vers le haut'
        },
        moveDown: {
          'Move down': 'Déplacer vers le bas'
        },
        image: {
          'With border': 'Ajouter des bords'
        }
      }
    }
  }
})
