import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Icon from './Icon'

const Container = styled.div`
  border: 1px solid ${colors.blue};
  color: ${colors.blue};
  background-color: ${colors.blueLight};
  /* vertical-align: baseline; */
  border-radius: ${spacing['1/2']};
  padding: ${spacing[1]} ${spacing[1]};
  display: flex;
  align-items: center;

  &, & * {
    font-size: 0.9375rem;
  }
`

export default function Alert ({ children }) {
  return (
    <Container>
      <Icon icon='info_blue.svg' />

      <div style={{ marginLeft: spacing[1] }}>
        {children}
      </div>
    </Container>
  )
}
