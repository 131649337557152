import { useEffect, useState } from 'react'
import Login from './Login'
import supabase from '../supabase'
import SetPassword from './SetPassword'
import styled, { createGlobalStyle } from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Header from './Header'
import Center from './Center'
import Link from './Link'
import Spacing from './Spacing'
import currentEnvironment from '../currentEnvironment'
import environmentEnums from '../enums/environments'
import UserContext from '../contexts/UserContext'
import OrganizationContext from '../contexts/OrganizationContext'
import globalStyle from '../globalStyle.js'
import DocumentsContext from '../contexts/DocumentsContext'
import Modal from './Modal'
import sendMessageToExtension from '../sendMessageToExtension'
import { useNavigate } from 'react-router-dom'
import browser from '../browser'

const GlobalStyle = createGlobalStyle`
  /* body, html, #documirRoot {
    background-color: ${colors.background};
  } */
`

const Background = styled.div`
  padding: ${spacing[4]} 0 0;
  flex: 1;

  @media only screen and (max-width: calc(${spacing[64]} + 32px)) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`

const Container = styled.div`
  background-color: ${colors.white};
  padding: ${spacing[2]};
  border: 1px solid ${colors.lightGrey};
  border-radius: ${spacing['1/2']};
  max-width: 100%;
  width: ${spacing[64]};
  box-shadow: 0px 1px 4px rgba(0,0,0,0.05);
  margin: auto;

  @media only screen and (max-width: calc(${spacing[64]} + 32px)) {
    border-radius: 0px;
    box-shadow: none;
    border-width: 0px 0px 1px 0px;
  }
`

function App ({ children }) {
  const [authUser, setAuthUser] = useState(supabase.auth.user())
  const [publicUser, setPublicUser] = useState()
  // const [organizationData, setOrganizationData] = useState()
  const [organizationData, setOrganizationData] = useState()
  const [onboardingUpdatedAt, setOnboardingUpdatedAt] = useState()
  const [hasDocumentsNotValidated, setHasDocumentsNotValidated] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [openModalOnboarding, setOpenModalOnboarding] = useState(false)
  const navigate = useNavigate()

  const updateUser = async () => {
    const { data } = await supabase
      .from('users')
      .upsert({ id: authUser.id, last_visit: new Date(), email: authUser.email })

    setPublicUser(data[0])

    sendMessageToExtension({
      message: {
        accessToken: supabase.auth.session().access_token,
        user: data[0]
      }
    })
  }

  useEffect(() => { // On user update > save its login data
    if (authUser) {
      updateUser()
    }
  }, [authUser])

  useEffect(() => { // SSO if previously signed in elsewhere
    const main = async () => {
      const accessTokenPayload = await browser?.storage?.sync?.get('accessToken')

      if (accessTokenPayload) {
        const { accessToken } = accessTokenPayload
        await supabase.auth.setAuth(accessToken)
        const { user, error } = await supabase.auth.api.getUser(accessToken)

        if (!error) {
          setAuthUser(user)
        }
      } else if (!authUser) {
        // Reset everything if not logged in
        sendMessageToExtension({ message: 'logout' })
      }
    }

    main()
  }, [])

  useEffect(() => { // Handling sign up and password reset
    const main = async () => {
      if (window.location.hash.includes('type=signup')) {
        const refreshToken = window.location.hash.split('refresh_token=')[1].split('&')[0]

        supabase.auth.signIn({ refreshToken })
          .then(() => {
            setAuthUser(JSON.parse(JSON.stringify(supabase.auth.user())))
            setOpenModalOnboarding(true)
          })
      } else if (window.location.hash.includes('type=magiclink')) {
        const refreshToken = window.location.hash.split('refresh_token=')[1].split('&')[0]
        await supabase.auth.signIn({ refreshToken })
        setAuthUser(JSON.parse(JSON.stringify(supabase.auth.user())))
        setOpenModalOnboarding('userAlreadyExists')
      } else if (window.location.hash.includes('token_type=bearer') && !window.location.hash.includes('type=recovery') && !window.location.hash.includes('type=invite')) {
        const refreshToken = window.location.hash.split('refresh_token=')[1].split('&')[0]
        const { user } = await supabase.auth.signIn({ refreshToken })

        const userInDatabase = await supabase
          .from('users')
          .select()
          .eq('email', user.email)

        setAuthUser(JSON.parse(JSON.stringify(supabase.auth.user())))
        navigate('/app')

        if (!userInDatabase.data.length) {
          setOpenModalOnboarding(true)
        }
      }
    }

    main()
  }, [])

  const getOrganizationData = async (fromCache) => {
    if (fromCache && organizationData) {
      return organizationData
    }

    if (publicUser?.id) {
      let { data } = await supabase
        .from('users')
        .select(`
        organization_id (
          id,
          reminders_enabled,
          teams (
            *,
            users_to_teams (
              *,
              users (
                *
              )
            )
          ),
          users (
            *
          )
        )
      `)
        .eq('id', publicUser?.id)

      data = data[0].organization_id
      setOrganizationData(data)
      return data
    }

    return null
  }

  return (
    <UserContext.Provider value={{ user: publicUser, setAuthUser, updateUser, onboardingUpdatedAt, setOnboardingUpdatedAt }}>
      <DocumentsContext.Provider value={{ setHasDocumentsNotValidated, hasDocumentsNotValidated }}>
        <OrganizationContext.Provider value={{ organizationData, getOrganizationData }}>
          <style>{globalStyle}</style>

          <Modal open={openModal} onClose={setOpenModal} hideHeader doublePadding>
            {openModal}
          </Modal>

          <Modal open={openModalOnboarding} onConfirm={() => { setOpenModalOnboarding(false); navigate('/app') }} title='Bienvenue sur Documir' hideCancelButton confirmButtonText='Commencer'>
            {
            openModalOnboarding === 'userAlreadyExists'
              ? (
                <>
                  Votre compte a bien été rattaché à l'organisation de votre collègue !
                </>
                )
              : (
                <>
                  Votre compte a bien été créé ! Nous vous conseillons maintenant de suivre notre guide de prise en main de Documir (dans le bloc bleu en haut de page).
                  {/* <br />
                  <br />
                  Pour vous aider à prendre en main Documir, nous vous invitons à lire cette courte documentation (3 minutes de lecture) :
                  <Spacing block y={2} />
                  <Button width='100%' primary icon='open_white.svg' href='https://samymnasri.notion.site/Comment-utiliser-Documir-aceb43d31ba341a9ab211a3a268e852b' target='_blank' rel='noreferrer'>Lire la documentation de Documir</Button>
                  <Spacing block y={2} />

                  <Alert>
                    Vous pourrez la retrouver à tout moment dans votre compte
                  </Alert> */}
                </>
                )
          }
          </Modal>

          <Background>
            <Container>
              {
                authUser
                  ? (
                    <div>
                      <Header hasDocumentsNotValidated={hasDocumentsNotValidated} />

                      {children}

                    </div>
                    )
                  : (
                      (window.location.hash.includes('type=recovery') || window.location.hash.includes('type=invite'))
                        ? <SetPassword
                            type={window.location.hash.includes('type=recovery') ? 'recovery' : 'invite'}
                            onLogin={(user, options) => { if (options?.openModalOnboarding) { setOpenModalOnboarding(true) } setAuthUser(user) }}
                          />
                        : <Login onLogin={setAuthUser} />
                    )
              }
            </Container>

            {currentEnvironment === environmentEnums.WEB && (
              <>
                <Spacing block y={2} />

                <Center>
                  <div style={{ color: colors.grey }}>
                    <span>contact@documir.com</span>
                    <Spacing x='1/2' />
                    <span> • </span>
                    <Spacing x='1/2' />
                    <Link target='_blank' rel='noreferrer' href='https://samymnasri.notion.site/Documir-Mentions-l-gales-74b92415fd05493bb4550603949aafe3'>Mentions légales</Link>
                  </div>
                </Center>
                <Spacing block y={2} />
              </>
            )}
          </Background>

          <GlobalStyle />
        </OrganizationContext.Provider>
      </DocumentsContext.Provider>
    </UserContext.Provider>
  )
}

export default App
