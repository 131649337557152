import colors from '../colors'
import globalStyle from '../globalStyle'
import spacing from '../spacing'
import Button from './Button'
import Spacing from './Spacing'
import Link from './Link'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import currentEnvironment from '../currentEnvironment'
import environments from '../enums/environments'
import { useEffect } from 'react'

const containerStyle = {
  maxWidth: '1024px',
  margin: 'auto'
}

const notionColors = {
  green: '#dbeddb',
  blue: '#d3e5ef',
  yellow: '#fdecc8',
  red: '#ffe2dd',
  orange: '#fadec9',
  brown: '#eee0da',
  pink: '#f5e0e9',
  purple: '#e8deee'
}

const h1Style = {
  fontSize: '2rem',
  fontWeight: '600',
  lineHeight: '3rem'
}

const h2Style = {
  fontSize: '1.25rem',
  fontWeight: '600',
  lineHeight: '2rem'
}

const BulletPoint = ({ children }) => {
  return (
    <div style={{ marginBottom: '0.5rem', textAlign: 'left' }}>
      {/* <span style={{ fontSize: '2rem', verticalAlign: '-4px', marginRight: '1rem' }}>•</span> */}
      <img src='/notion/check.svg' style={{ height: '1.5rem', verticalAlign: 'middle', marginRight: '0.5rem' }} />

      <span style={{ verticalAlign: 'middle' }}>
        {children}
      </span>
    </div>
  )
}

const ThreePoints = ({ points, heading, isSmallScreen, backgroundColor }) => {
  return (
    <div style={{ padding: '0px 1rem', backgroundColor: backgroundColor || colors.beige }}>
      <div style={{ ...containerStyle, borderBottom: '1px solid rgba(0,0,0,0.075)', padding: `${spacing[8]} 0px` }}>
        <div style={{ textAlign: 'center', ...h1Style }}>{heading}</div>
        <Spacing y={8} />

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', flexDirection: isSmallScreen ? 'column' : 'row' }}>
          {points.map((point, index) => (
            <div
              key={index}
              style={{
                marginLeft: isSmallScreen ? '0px' : index === 0 ? '0px' : '2rem',
                flex: '1',
                marginTop: isSmallScreen ? (index === 0 ? '0px' : '2rem') : '0px',
                width: '100%',
                // border: '1px solid ' + colors.lightGrey,
                borderRadius: spacing['1'],
                padding: isSmallScreen ? '0' : spacing[4],
                backgroundColor: isSmallScreen ? 'transparent' : colors.white,
                boxShadow: isSmallScreen ? 'none' : '0px 2px 8px rgba(0,0,0,0.125)'
              }}
            >
              {point.icon && (
                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                  <img height='128rem' src={point.icon} />
                </div>
              )}

              <div style={{ ...h2Style, textAlign: isSmallScreen ? 'center' : 'left' }}>{point.title}</div>
              <Spacing y={2} />
              <div>{point.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const Banner = ({ title, children, image, color, reverse, isSmallScreen, figma }) => {
  return (
    <div style={{ padding: '0px 1rem' }}>
      <div style={{ ...containerStyle, borderBottom: '1px solid rgba(0,0,0,0.075)', padding: `${spacing[8]} 0px`, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isSmallScreen ? 'column' : reverse ? 'row-reverse' : 'row' }}>
        <div style={{ flex: '1' }}>
          <div style={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
            <div style={{ textAlign: 'center', padding: '1rem 1rem', display: 'inline-flex', backgroundImage: `url(/rocks/${color}.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }}>
              <img style={{ width: '4rem', height: '100%' }} src={image} />
            </div>
          </div>

          <Spacing y={2} x={2} />
          <div style={{ fontSize: '2rem', fontWeight: '600', lineHeight: '3rem', textAlign: isSmallScreen ? 'center' : 'left' }}>{title}</div>
          <Spacing y={4} />

          <div style={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
            {children}
          </div>
        </div>

        <Spacing y={8} x={8} />

        <div>
          <img style={{ maxWidth: spacing[48], width: '100%', borderRadius: '8px', boxShadow: '0px 2px 8px rgba(0,0,0,0.25)' }} src={figma} />
        </div>
      </div>
    </div>
  )
}

export default function HomePage () {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' })
  const navigate = useNavigate()

  useEffect(() => {
    if (environments.EXTENSION === currentEnvironment) {
      navigate('/app')
    }
  }, [])

  return (
    <div style={{ backgroundColor: colors.beige }}>
      <style>{globalStyle}</style>

      <div style={{ position: 'sticky', top: '0', backgroundColor: colors.beige, zIndex: 999 }}>
        <div style={{ ...containerStyle, padding: `${spacing[2]} 0`, borderBottom: '1px solid rgba(0,0,0,0.075)' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', maxWidth: 'calc(100vw - 2rem)', margin: 'auto' }}>
            <div style={{ display: 'flex' }}>
              <img style={{ height: '1.5rem' }} src='/documir48.png' />
              <Spacing x={2} />

              <div style={{ fontWeight: '600' }}>
                Documir
              </div>
            </div>

            <div>
              <Button href='/app' primary>Commencer</Button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: colors.beige, padding: '0px 1rem' }}>
        <div style={{ ...containerStyle, borderBottom: '1px solid rgba(0,0,0,0.075)', padding: `${spacing[8]} 0px`, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: isSmallScreen ? 'column' : 'row' }}>
          <div>
            <div style={{ fontSize: '2rem', fontWeight: '700', lineHeight: '3rem', textAlign: isSmallScreen ? 'center' : 'left' }}>
              Tirez le meilleur de votre documentation, sans effort.
            </div>

            <Spacing y={4} />
            <div style={{ fontSize: '1.125rem', lineHeight: '2rem' }}>La documentation interne, elle est souvent introuvable, obsolète, chronophage, incomplète, incomprise, pas consultée... Ça ne devrait pas être le cas.</div>
            <Spacing y={2} />
            <div style={{ fontSize: '1.125rem', lineHeight: '2rem', fontWeight: 600 }}>Documir vous aide à résoudre tous vos problèmes de documentation.</div>
            <Spacing y={4} />

            <div style={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
              <Button href='/app' primary>Commencer gratuitement</Button>
            </div>
          </div>

          <Spacing x={8} y={8} />

          <div style={{ maxWidth: '100%', padding: isSmallScreen ? `${spacing[0]} ${spacing[8]} ${spacing[0]}` : 0 }}>
            <img style={{ maxWidth: '512px', minWidth: '128px', width: '100%', padding: '1rem' }} src='/notion/effortless_with_container.svg' />
          </div>
        </div>
      </div>

      <div style={{ padding: '0px 1rem' }}>
        <div style={{ ...containerStyle, borderBottom: '1px solid rgba(0,0,0,0.075)', padding: `${spacing[8]} 0px` }}>
          <div style={{ fontSize: '1.125rem', fontWeight: '400', lineHeight: '2.5rem', textAlign: 'left', color: 'black' }}>
            {/* <span style={{ color: 'black', fontSize: '1.125rem', fontWeight: '400' }}>Une mauvaise documentation</span>, ça fait : */}
            <div style={{ textAlign: 'center', ...h1Style }}>Une mauvaise documentation, ça fait ...</div>
            <Spacing y={4} />
            {' '}<span style={{ display: 'inline-block', backgroundColor: notionColors.pink, borderRadius: '8px', padding: '0.25rem 0.5rem', fontSize: '1.125rem', fontWeight: '600 ', color: 'black' }}>Perdre du temps</span> à comprendre ce qui avait été fait,
            {' '}<span style={{ display: 'inline-block', backgroundColor: notionColors.green, borderRadius: '8px', padding: '0.25rem 0.5rem', fontSize: '1.125rem', fontWeight: '600  ', color: 'black' }}>faire des erreurs</span> à répétition,
            {' '}<span style={{ display: 'inline-block', backgroundColor: notionColors.yellow, borderRadius: '8px', padding: '0.25rem 0.5rem', fontSize: '1.125rem', fontWeight: '600 ', color: 'black' }}>déranger</span> sans cesse les mêmes personnes,
            {' '}<span style={{ display: 'inline-block', backgroundColor: notionColors.purple, borderRadius: '8px', padding: '0.25rem 0.5rem', fontSize: '1.125rem', fontWeight: '600 ', color: 'black' }}>bâcler l'onboarding</span> des nouvelles recrues...
            <Spacing x={2} />
            <span style={{ fontWeight: '600', fontSize: '1.125rem' }}>Épargnez-vous ces problèmes en utilisant le bon outil.</span>
          </div>
        </div>
      </div>

      <ThreePoints
        heading="Documir, c'est :"
        isSmallScreen={isSmallScreen}
        backgroundColor={colors.beige}
        points={[
          {
            title: 'Un outil web pour votre équipe',
            description: 'Pour résoudre tous les problèmes que vous rencontrez avec votre documentation',
            icon: '/notion/team.svg'
          },
          {
            title: 'Une extension Chrome et Firefox',
            description: 'Pour retrouver la documentation dont vous avez besoin, au moment où vous en avez besoin grâce à notre extension (facultative)',
            icon: '/notion/module.svg'
          },
          {
            title: 'Un ensemble de bonnes pratiques',
            description: 'Nous automatisons la mise en place des meilleures pratiques en termes de documentation',
            icon: '/notion/best.svg'
          }
        ]}
      />

      <Banner isSmallScreen={isSmallScreen} color='blue' title='Plus aucune documentation ne sera obsolète, incomplète ou absente' image='/notion/complete.svg' figma='/figma/to_complete.svg'>
        <BulletPoint>Assignez un responsable à chaque documentation pour qu'elles soient bien entretenues régulièrement</BulletPoint>
        <BulletPoint>Si quelque chose n'est pas bien documenté, envoyez des demandes de rédaction/modification de documentation à vos collègues</BulletPoint>
        <BulletPoint>Chaque semaine nous vous rappelerons quelles documentations doivent être rédigées ou mises à jour</BulletPoint>
        <BulletPoint>Priorisez vos demandes de rédaction/modification pour rester concentré sur les documentations à forte valeur ajoutée</BulletPoint>
      </Banner>
      

      <Banner isSmallScreen={isSmallScreen} color='purple' reverse title="ChatGPT vous aidera à comprendre votre documentation en une question" image='/notion/medal.svg' figma='/figma/quiz.svg'>
        <BulletPoint>Demandez ce que vous voulez sur votre documentation, ChatGPT vous répondra</BulletPoint>
        <BulletPoint>ChatGPT ira chercher la réponse parmi le contenu de vos documentations</BulletPoint>
        <BulletPoint>Nous utilisons la dernière version de GPT pour encore plus de précision</BulletPoint>
      </Banner>

      <Banner isSmallScreen={isSmallScreen} color='pink' title='Ne perdez plus de temps à retrouver une documentation' image='/notion/search.svg' figma='/figma/icons.svg'>
        <BulletPoint>Retrouvez toutes les documentations dont vous avez besoin en 1 clic, sans avoir à passer des heures à les chercher</BulletPoint>
        <BulletPoint>Notre extension clignotera en rouge dès que vous visitez une page pour laquelle une documentation est pertinente</BulletPoint>
        <BulletPoint>Vos documentations apparaitront directement sur les pages visitée, sous forme de petit badge cliquable, aux endroits où vous en aurez besoin</BulletPoint>
      </Banner>

      <Banner isSmallScreen={isSmallScreen} color='green' reverse title='Votre documentation sera enfin lue et comprise par tout le monde' image='/notion/read.svg' figma='/figma/to_validate.svg'>
        <BulletPoint>Nous informerons vos collègues dès qu'une nouvelle documentation doit être lue</BulletPoint>
        <BulletPoint>Configurez en 30 secondes des quizz/QCM pour valider la connaissance d'une documentation</BulletPoint>
        <BulletPoint>Des alertes emails vous rappelerons quelles documentations n'ont pas encore été consultées</BulletPoint>
        <BulletPoint>Déterminez quelles équipes sont concernées par telle ou telle documentation</BulletPoint>
      </Banner>
      
      <Banner isSmallScreen={isSmallScreen} color='red' title="Comprenez votre organisation et ses documentations en un coup d'œil" image='/notion/checklist.svg' figma='/figma/checklist.svg'>
        <BulletPoint>Liez des documentations entre elles pour facilement retrouver leurs dépendances</BulletPoint>
        <BulletPoint>Des diagrammes de dépendance seront automatiquement générés pour vous aider à comprendre votre organisation</BulletPoint>
        <BulletPoint>Naviguez parmi vos documentations à partir de ces diagrammes de dépendance</BulletPoint>
      </Banner>

      <Banner isSmallScreen={isSmallScreen} reverse color='yellow' title="Commencez à rédiger une nouvelle documentation en un clin d'œil" image='/notion/idea.svg' figma='/figma/extension.svg'>
        <BulletPoint>Créez une documentation en un clic avec nos extensions Chrome et Firefox</BulletPoint>
        <BulletPoint>Rédigez votre documentation directement depuis Documir avec notre éditeur intégré</BulletPoint>
        <BulletPoint>Vous pouvez aussi conserver votre documentation sur un autre outil (Notion, Confluence, etc.) et utiliser Documir en complément</BulletPoint>
        <BulletPoint>Reprenez nos templates de documentation conçus à partir des meilleures pratiques en la matière</BulletPoint>
      </Banner>

      <ThreePoints
        heading="Exemples d'utilisations"
        isSmallScreen={isSmallScreen}
        points={[
          {
            title: 'Managers opérationnels',
            description: "Ne laissez pas vos équipes opérer sans maitriser vos process : documentez-les tous et assurez-vous qu'ils sont bien maitrisés via nos quizz, checklistes et automatisations pour ainsi réduire vos efforts de formation et les erreurs opérationnelles"
          },
          {
            title: 'Product Managers & développeurs',
            description: 'Ne laissez pas vos outils, produits, ou fonctionnalités développées sans documentation : Documir vous permettra de facilement détecter les documentations manquantes, de les compléter et les communiquer sans effort'
          },
          {
            title: 'Ressources Humaines',
            description: "Ne laissez pas vos nouvelles recrues sans la formation qu'ils méritent : automatisez une partie de votre process d'onboarding en le documentant et assurez-vous que tout le monde est bien onboardé via notre système de quizz"
          }
        ]}
      />

      <div style={{ backgroundColor: colors.beige, padding: '0px 1rem' }}>
        <div style={{ ...containerStyle, padding: `${spacing[8]} 0px`, textAlign: 'center' }}>
          <div style={{ fontSize: '2rem', fontWeight: '600', lineHeight: '3rem', maxWidth: '780px', margin: 'auto' }}>
            Ne subissez plus vos problèmes de documentation,
            essayez Documir gratuitement
            pour toute votre équipe.
          </div>

          <Spacing y={4} />
          <div style={{ fontSize: '1.125rem', lineHeight: '2.5rem' }}>Documir est en bêta-test et restera gratuit pendant 6 mois pour tous les bêta-testeurs</div>
          <Spacing y={4} />

          <Button href='/app' primary>Commencer gratuitement</Button>
        </div>
      </div>

      <div style={{ ...containerStyle, borderTop: '1px solid rgba(0,0,0,0.075)', padding: `${spacing[4]} 0` }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center', maxWidth: 'calc(100vw - 2rem)', margin: 'auto' }}>
          <span>
            © {new Date().getFullYear()} Documir
          </span>

          <Spacing y={2} />
          <span>contact@documir.com</span>
          <Spacing y={2} />
          <Link target='_blank' rel='noreferrer' href='https://samymnasri.notion.site/Documir-Mentions-l-gales-74b92415fd05493bb4550603949aafe3'>Mentions légales</Link>
        </div>
      </div>
    </div>
  )
}
