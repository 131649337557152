import { useContext, useEffect, useState } from 'react'
import colors from '../colors'
import UserContext from '../contexts/UserContext'
import spacing from '../spacing'
import supabase from '../supabase'
import Badge from './Badge'
import Input from './Input'
import Spacing from './Spacing'

const TagsSelector = function ({ createdTags, selectedTags, setSearch, setTags, tag: externalSearch, selectedTagsLabel, addTagLabel }) {
  const [internalSearch, setInternalSearch] = useState()
  const [availableTags, setAvailableTags] = useState()
  const tagsIds = (selectedTags || []).map(el => el?.id)
  const { user } = useContext(UserContext)
  const tag = typeof externalSearch !== 'undefined' ? externalSearch : internalSearch

  const getAvailableTags = async () => {
    const { data } = await supabase.from('available_tags')
      .select()
      .eq('user_id', user?.id)

    setAvailableTags(data)
  }

  useEffect(() => {
    if (user && !availableTags) {
      getAvailableTags()
    }
  }, [user])

  const mergedAvailableTags = [...(createdTags || []), ...(availableTags || [])]

  return (
    <div>
      {selectedTags?.length
        ? (
          <>
            <div style={{ color: colors.grey, fontSize: '15px' }}>
              {selectedTagsLabel || 'Tags ajoutés à cette documentation'}
            </div>

            <Spacing y='1/2' />
          </>
          )
        : null}

      {(selectedTags || []).map((tag, index) => (
        <span key={tag.id + 'selected'} style={{ display: 'inline-block', paddingRight: spacing[1], paddingBottom: spacing[1] }}>
          <Badge onRemove={() => setTags((selectedTags || []).filter(el => el.id !== tag.id))} isRemovable isTag>{tag.tag}</Badge>
        </span>
      ))}

      {(selectedTags || []).length
        ? (
          <Spacing y={1} />
          )
        : null}

      <Input value={tag} onChange={e => { setSearch && setSearch(e.target.value); setInternalSearch(e.target.value) }} type='text' width='100%' label={addTagLabel || 'Chercher un tag à ajouter'} placeholder={addTagLabel || 'Tag à ajouter'} />
      {/* <Spacing y={1} /> */}

      {(mergedAvailableTags || []).filter(el => (!tag || el.tag.toLowerCase().includes(tag.toLowerCase())) && !tagsIds.includes(el.id)).map(el => (
        <span key={el.id + 'available'} style={{ display: 'inline-block', paddingRight: spacing[1], paddingTop: spacing[1] }}>
          <Badge light isClickable onClick={() => setTags([...selectedTags, el])} isTag>
            {el.tag}
          </Badge>
        </span>
      ))}
    </div>
  )
}

export default TagsSelector
