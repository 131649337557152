import React, { useContext, useEffect, useState } from 'react'
import Document from './Document'
import Button from './Button'
import Spacing from './Spacing'
import currentEnvironment from '../currentEnvironment'
import environmentEnums from '../enums/environments'
import UserContext from '../contexts/UserContext'
import _getDocuments from '../getDocuments'
import DocumentsContext from '../contexts/DocumentsContext'
import Alert from './Alert'
import { Link, useNavigate } from 'react-router-dom'
import userRoles from '../enums/userRoles'
import documentStatusEnum from '../enums/documentStatusEnum'
import sendMessageToExtension from '../sendMessageToExtension'
import Input from './Input'
import Tabs from './Tabs'
import Snackbar from './Snackbar'
import MenuItem from './MenuItem'
import { useMediaQuery } from 'react-responsive'
import Modal from './Modal'
import OrganizationContext from '../contexts/OrganizationContext'
import supabase from '../supabase'
import createDefaultCondition from '../createDefaultCondition'
import TagsSelector from './TagsSelector'
import spacing from '../spacing'
import browser from '../browser'
import documentIsNotValidated from '../content/documentIsNotValidated'

const enums = {
  ALL: 'ALL',
  TO_VALIDATE: 'TO_VALIDATE',
  RELATED: 'RELATED',
  TO_COMPLETE: 'TO_COMPLETE',
  MY_DOCUMENTATIONS: 'MY_DOCUMENTATIONS',
  MY_REQUESTS: 'MY_REQUESTS'
}

const Documents = ({ all, notValidated }) => {
  const { setHasDocumentsNotValidated, hasDocumentsNotValidated } = useContext(DocumentsContext)
  const [documents, setDocuments] = useState()
  const [selectedTags, setSelectedTags] = useState([])
  const selectedTagsIds = selectedTags ? selectedTags.map(el => el.id) : []
  // const [tag, setTag] = useState()
  const [matchedDocuments, setMatchedDocuments] = useState()
  const { user } = useContext(UserContext)

  const documentsNotValidated = documents && documents.filter(documentIsNotValidated)

  const documentsToComplete = (documents || []).filter(document => (
    document.status === documentStatusEnum.TO_CREATE.id &&
    document.user_id === user?.id
  ) || (
    document.update_frequency && (
      (new Date() - new Date(document.updated_at || document.created_at)) > (document.update_frequency * 1000 * 60 * 60 * 24 * 30)
    )
  ))

  const tabs = [
    { label: 'Toutes', value: enums.ALL },
    { label: 'À valider', value: enums.TO_VALIDATE, redDot: hasDocumentsNotValidated },
    { label: 'À compléter', value: enums.TO_COMPLETE, redDot: documentsToComplete.length }
    // { label: 'Mes documentations', value: enums.MY_DOCUMENTATIONS }
    // { label: 'Mes demandes', value: enums.MY_REQUESTS }
  ]

  if (currentEnvironment === environmentEnums.EXTENSION) {
    tabs.unshift({ label: 'Lié à cette page', value: enums.RELATED })
  }

  const [tab, setTab] = useState(tabs[0].value)
  const [search, setSearch] = useState()
  const [letGptOpen, setLetGptOpen] = useState()
  const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' })
  const [openSnack, setOpenSnack] = useState(false)
  const [openSnackRequest, setOpenSnackRequest] = useState(false)
  const [openSnackGpt, setOpenSnackGpt] = useState(false)
  const [openRequestModal, setOpenRequestModal] = useState(false)
  const [openSearch, setOpenSearch] = useState()
  const [openFilter, setOpenFilter] = useState(false)
  const [extensionIsInstalled, setExtensionIsInstalled] = useState(false)
  const [requestName, setRequestName] = useState()
  const [requestUser, setRequestUser] = useState()
  const [currentTabUrl, setCurrentTabUrl] = useState()
  const { organizationData, getOrganizationData } = useContext(OrganizationContext)
  const navigate = useNavigate()

  const documentsToShow = (
    tab === enums.RELATED
      ? matchedDocuments
      : tab === enums.ALL
        ? documents
        : tab === enums.TO_COMPLETE
          ? documentsToComplete
          : documentsNotValidated
  )?.filter(document => (
    (
      !search ||
      document.name.toLowerCase().includes(search.toLowerCase())
    ) && (
      !selectedTagsIds.length ||
      document.documents_to_tags?.filter(el => selectedTagsIds.includes(el.tag_id.id)).length
    )
  ))

  useEffect(() => {
    if (!organizationData) {
      getOrganizationData(true)
    }
  }, [organizationData, user])

  useEffect(() => {
    if (currentEnvironment === environmentEnums.EXTENSION) {
      browser?.tabs?.query({ active: true, lastFocusedWindow: true }, tabs => {
        setCurrentTabUrl(tabs[0].url)
      })
    }
  }, [])

  useEffect(() => {
    setHasDocumentsNotValidated(documentsNotValidated?.length)
  }, [documentsNotValidated])

  useEffect(() => {
    sendMessageToExtension({
      message: 'isDocumirInstalled',
      callback: isInstalled => {
        if (isInstalled) {
          setExtensionIsInstalled(true)
        }
      }
    })
  }, [])

  const getDocuments = async () => {
    if (user) {
      const { data } = await _getDocuments(user)
      setDocuments(data)

      browser?.storage?.sync?.get('matchedDocuments', ({ matchedDocuments }) => {
        setMatchedDocuments(matchedDocuments)
      })
    }
  }

  useEffect(() => {
    if (user) {
      getDocuments()
    }
  }, [user])

  useEffect(() => {
    browser?.runtime?.onMessage?.addListener((message) => {
      if (message === 'renderDocuments') {
        getDocuments()
      }
    })
  }, [])

  const handleRequest = async () => {
    const { data } = await supabase
      .from('documents')
      .insert([{
        name: requestName,
        user_id: requestUser,
        status: documentStatusEnum.TO_CREATE.id,
        conditions: createDefaultCondition(false, true)
      }])

    setOpenRequestModal(false)
    getDocuments()

    fetch((window.location.hostname.includes('localhost') ? 'http://localhost:3000/api/request-document?' : 'https://www.documir.com/api/request-document?') + new URLSearchParams({
      requestedByUserEmail: user?.email,
      userId: requestUser,
      documentName: requestName,
      documentId: data[0].id
    }))

    setOpenSnackRequest(true)
  }

  const handleRequestGpt = () => {
    fetch((window.location.hostname.includes('localhost') ? 'http://localhost:3000/api/request-gpt?' : 'https://www.documir.com/api/request-gpt?') + new URLSearchParams({
      userEmail: user?.email
    }))

    setOpenSnackGpt(true)
  }

  const shouldShowAddButton = (user?.role !== userRoles.USER || !user?.organization_id)

  return (
    <div>
      <Snackbar
        onClose={() => setOpenSnack(false)}
        open={openSnack}
        message='La documentation a bien été supprimée'
      />

      <Snackbar
        onClose={setOpenSnackGpt}
        open={openSnackGpt}
        message='Votre demande a bien été prise en compte, nous reviendrons vers vous très bientôt !'
      />

      <Snackbar
        onClose={() => setOpenSnackRequest(false)}
        open={openSnackRequest}
        message='La demande a bien été envoyée par email'
      />

      <Modal title='Tags des documentations à afficher' open={openFilter} onClose={setOpenFilter}>
        <TagsSelector
          selectedTags={selectedTags || []}
          setTags={tags => setSelectedTags([...tags])}
          selectedTagsLabel='Afficher les documentations qui ont ces tags'
          addTagLabel='Chercher un tag à ajouter aux filtres'
          // tag={tag}
          // setSearch={setTag}
        />
      </Modal>

      <Modal open={openRequestModal} onClose={setOpenRequestModal} title='Demander une documentation à un collègue' onConfirm={handleRequest} confirmButtonText='Envoyer la demande'>
        <Input onChange={e => setRequestName(e.target.value)} label='Documentation à demander *' placeholder='Exemple : Comment faire pour ... ?' width='100%' />
        <Spacing y={2} />

        <Input onChange={e => setRequestUser(e.target.value)} label='Collègue à qui demander *' type='select' width='100%'>
          <option>-- Sélectionner un collègue --</option>

          {organizationData?.users.filter(user => user.role !== userRoles.USER).map(user => (
            <option value={user.id} key={user.id}>{user.email}</option>
          ))}
        </Input>

        <Spacing y={2} />

        <Alert>Un collègue n'apparait pas dans cette liste ? <Link style={{ textDecoration: 'underline' }} to='/app/organization'>Invitez-le d'abord sur Documir</Link></Alert>
      </Modal>

      {/* <Onboarding /> */}
      <Spacing y={2} />

      <div style={{ display: 'flex' }}>
        {shouldShowAddButton && (
          <>
            <Button
              primary
              menu={() => (
                <>
                  <MenuItem onClick={() => setOpenRequestModal(true)} icon='/send_black.svg'>
                    {isSmallScreen ? 'Demander une documentation' : 'Demander une documentation à un collègue'}
                  </MenuItem>
                </>
              )}
              width={openSearch ? '3rem' : '100%'}
              icon='add_white.svg'
              onClick={() => currentEnvironment === environmentEnums.EXTENSION ? window.open(`https://www.documir.com/app/documentation/new${currentTabUrl ? `?from=${window.encodeURIComponent(currentTabUrl)}` : ''}`, '_blank').focus() : navigate('/app/documentation/new')}
            >
              {!openSearch ? (isSmallScreen ? 'Ajouter' : 'Ajouter une documentation') : null}
            </Button>

            <Spacing x={2} />
          </>
        )}

        <Button
          secondary
          icon={selectedTags?.length ? '/filter_alt_blue.svg' : '/filter_alt_black.svg'}
          onClick={() => setOpenFilter(true)}
        />

        <Spacing x={2} />

        <Input
          onBlur={e => {
            if (!search && shouldShowAddButton) {
              setOpenSearch(null)
              document.querySelector('input#search').style.display = 'none'
            }
          }}
          id='search'
          style={{ display: shouldShowAddButton ? 'none' : 'block' }}
          type='search'
          width='100%'
          onChange={e => setSearch(e.target.value)}
          placeholder='Chercher une documentation ...'
        />

        {!openSearch && shouldShowAddButton && (
          <Button
            onClick={() => {
              setOpenSearch(true)
              document.querySelector('input#search').style.display = 'block'
              document.querySelector('input#search').focus()
              setLetGptOpen(true)
            }}
            secondary
            icon='/search.svg'
          />
        )}
      </div>
      <Spacing y={1} />

      <Tabs
        setTab={setTab}
        activeTab={tab}
        tabs={tabs}
      />

      {
        tab === enums.RELATED && documentsToShow
          ? (
            <>
              {
                documentsToShow.length
                  ? documentsToShow.map(document => <Document onUpdated={getDocuments} key={document.id} document={document} />)
                  : (
                    <>
                      <Spacing block y={2} />
                      {
                        (search || selectedTagsIds.length)
                          ? 'Aucun résultat'
                          : "Aucune documentation n'est liée à cette page"
                      }
                    </>
                    )
              }
            </>
            )
          : tab === enums.RELATED && (
            <>
              <Spacing block y={2} />

              <Alert>
                Vous retrouverez ci-dessous les documentations liées aux pages que vous êtes en train de visiter
              </Alert>

              <Spacing block y={2} />

              <div>
                {
                  currentEnvironment === environmentEnums.WEB
                    ? extensionIsInstalled
                      ? "Pour accéder à cette fonctionnalité, cliquez sur l'extension Documir en haut à droite du navigateur"
                      : "Pour accéder à cette fonctionnalité, installez l'extension Documir puis cliquez sur l'icone de l'extension en haut à droite du navigateur"
                    : "Aucune documentation n'a été ajoutée pour cette page"
                }
              </div>
            </>
          )
      }

      {
        (tab === enums.ALL || tab === enums.TO_VALIDATE || tab === enums.TO_COMPLETE) && documentsToShow?.length
          ? (
            <>
              {
                documentsToShow.map(document => (
                  <Document
                    onDelete={() => setOpenSnack(true)}
                    onUpdated={getDocuments}
                    key={document.id}
                    document={document}
                  />
                ))
              }
            </>
            )
          : (tab === enums.ALL || tab === enums.TO_VALIDATE || tab === enums.TO_COMPLETE) && (
            <>
              <Spacing block y={2} />
              <div>
                {
                  (search || selectedTagsIds.length)
                    ? 'Aucun résulat'
                    : tab === enums.TO_VALIDATE
                      ? 'Aucune documentation à valider'
                      : tab === enums.TO_COMPLETE
                        ? 'Aucune documentation à compléter'
                        : "Aucune documentation n'a encore été ajouté"
                }
              </div>
            </>
            )
      }

      {tab === enums.MY_DOCUMENTATIONS && (
        <div>
          <Spacing y={2} />
          <div>Fonctionnalité en cours de développement</div>
        </div>
      )}

      {tab === enums.MY_REQUESTS && (
        <div>
          <Spacing y={2} />
          <div>Fonctionnalité en cours de développement</div>
        </div>
      )}

      {(search || openSearch || letGptOpen) && (
        <>

          <Spacing y='2' />
          <Alert>
            <div style={{ paddingLeft: spacing['1/2'] }}>
              <Spacing y='1/2' />
              Vous ne trouvez pas ce que vous cherchez ?
              <Spacing y='1/2' />
              <Button onClick={handleRequestGpt} primaryLight>Activer Chat GPT sur Documir</Button>
              <Spacing y='1/2' />
            </div>
          </Alert>
        </>
      )}
    </div>
  )
}

export default Documents
