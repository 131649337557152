import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import React from 'react'

const Styled = styled.span`
  color: ${colors.white};
  display: inline-block;

  &, & * {
    font-size: 15px;
  }

  ${props => props.isTag
    ? `
      background-color: ${props.light ? colors.blueLight : colors.blue};
      color: ${props.light ? colors.blue : 'white'};
      padding-top: 0;
      padding-bottom: 0;
      padding-left: ${spacing['1']};
      padding-right: ${props.isRemovable ? '0' : spacing['1']};
      border-radius: 4px;
      `
    : `
      background-color: ${props.color || colors.black};
      padding: ${spacing['1/2']} ${spacing[1]};
      border-radius: 100rem;
  `}

  ${props => props.circle && `
    width: 1.5rem;
    height: 1.5rem;
    padding: 0px 7px;
  `}

  ${props => props.isClickable && `
    cursor: pointer;
  `}
`

export default React.forwardRef(({ children, isClickable, onRemove, isRemovable, ...props }, ref) => {
  return (
    <Styled isClickable={isClickable} isRemovable={isRemovable} ref={ref} {...props}>
      <span style={{ paddingRight: isRemovable ? spacing[1] : '0' }}>
        {children}
      </span>

      {isRemovable && (
        <span onClick={onRemove} style={{ borderLeft: '1px solid white', padding: `${spacing[1]}`, cursor: 'pointer', color: colors.blueLight, fontSize: '15px' }}>x</span>
      )}
    </Styled>
  )
})
