import colors from '../colors'
import Alert from './Alert'
import Card from './Card'
import ConditionsLevel from './ConditionsLevel'
import Spacing from './Spacing'
import Link from './Link'

const EditDocumentConditionsCard = ({ conditions, setConditions, accordionIsOpenByDefault }) => {
  return (
    <Card accordionIsOpenByDefault={accordionIsOpenByDefault} isAccordion header='👀 Conditions de mise en avant' helper='La documentation sera mise en avant lorsque qu’une page visitée respecte ces conditions'>
      <div style={{ color: colors.grey, fontSize: '0.9375rem' }}>Quand mettre en avant la documentation sur les pages visitées ?</div>
      <Spacing y={1} />
      <ConditionsLevel conditions={conditions} setConditions={setConditions} level={1} />
      <Spacing y={2} />

      <Alert>
        Une documentation peut être mise en avant de plusieurs manières différentes,
        {' '}

        <Link
          underlined
          target='_blank'
          href='https://www.notion.so/samymnasri/Comment-utiliser-Documir-aceb43d31ba341a9ab211a3a268e852b#37e17cbb19454d4a9967ba9a394f013e'
          rel='noreferrer'
        >
          plus de détails ici
        </Link>
      </Alert>
    </Card>
  )
}

export default EditDocumentConditionsCard
