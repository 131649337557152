import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import supabase from '../supabase'
import Button from './Button'
import Spacing from './Spacing'
import Badge from './Badge'
import Tooltip from './Tooltip'
import UserContext from '../contexts/UserContext'
import userRoles from '../enums/userRoles'
import { useNavigate, Link } from 'react-router-dom'
import sendMessageToExtension from '../sendMessageToExtension'
import currentEnvironment from '../currentEnvironment'
import environments from '../enums/environments'
import documentStatusEnum from '../enums/documentStatusEnum'
import buildQuizUrl from '../buildQuizUrl'
import QuickQuizModal from './QuickQuizModal'
import Menu from './Menu'
import MenuItem from './MenuItem'
import Modal from './Modal'
import Icon from './Icon'
import browser from '../browser'
import validationTypeEnum from '../enums/validationTypeEnum'

const StyledDocument = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing['1']} 0px ${spacing['1']} ${spacing['1']} !important;
`

const linkStyle = `
  color: ${colors.black};
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: ${spacing['1/2']};

  &:hover, &:hover * {
    text-decoration: underline;
  }
`

const StyledLink = styled(Link)`
  ${linkStyle}
`

const StyledA = styled.a`
  ${linkStyle}
`

const ButtonContainer = styled.span`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: ${spacing[2]} !important;
`

export default function Document ({ document, onUpdated, onDelete, fromContentJs }) {
  const [userId, setUserId] = useState()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [documentToDelete, setDocumentToDelete] = useState()
  const [openModalQuickQuiz, setOpenModalQuickQuiz] = useState(false)
  const userContext = useContext(UserContext)
  const navigate = useNavigate()

  // Ne pas afficher dans l'extension pour qu'utilisateurs comprennent que c'est une page standalone
  const shouldNotOpenInAnotherTab = !document.is_external &&
    (window.location.hostname === 'localhost' || window.location.hostname === 'www.documir.com') &&
    !fromContentJs

  useEffect(() => {
    const getUserId = async () => {
      if (browser?.storage?.sync) {
        setUserId((await browser.storage.sync.get('user')).user.id)
      }
    }

    getUserId()
  }, [])

  const handleOpenDocument = async (event) => {
    await supabase
      .from('users_to_documents')
      .upsert({
        user_id: supabase?.auth?.user()?.id || userId,
        document_id: document.id,
        has_opened: true
      })

    sendMessageToExtension({ message: 'getDocuments' })
    userContext?.setOnboardingUpdatedAt && userContext?.setOnboardingUpdatedAt(new Date())
    onUpdated && onUpdated()
  }

  const documentUrl = document.is_external
    ? buildQuizUrl(document.url)
    : shouldNotOpenInAnotherTab
      ? `/app/documentation/${document.id}`
      : `https://www.documir.com/app/documentation/${document.id}`

  const documentEditUrl = shouldNotOpenInAnotherTab
    ? `/app/documentation/${document.id}/edit`
    : `https://www.documir.com/app/documentation/${document.id}/edit`

  const formatQuizUrl = () => {
    if (document.validation_type === validationTypeEnum.QUICK_QUIZ) {
      if (fromContentJs) {
        return documentUrl
      } else {
        return null
      }
    }

    const payload = JSON.stringify({
      userId: supabase?.auth?.user()?.id || userId,
      documentId: document.id
    })

    const quizUrl = buildQuizUrl(document.quiz)

    if (!quizUrl.includes('#')) {
      return quizUrl + '#documir=' + payload
    } else if (!quizUrl.includes('documir=')) {
      return quizUrl + '&documir=' + payload
    } else {
      const [baseUrl, parameters] = quizUrl.split('#')
      return baseUrl + '#' + parameters.split('&').map(el => el.includes('documir=') ? ('documir=' + payload) : el).join('&')
    }
  }

  const LinkContent = () => (
    // <span style={{ display: 'inline-flex', alignContent: 'center' }}>
    //   {!shouldNotOpenInAnotherTab && (
    //     <>
    //       <Icon icon='open_black.svg' />
    //       <Spacing x='1' />
    //     </>
    //   )}
    // </span>
    <>
      {/* <Spacing x='1/2' /> */}
      <span style={{ display: 'inline-block', verticalAlign: 'middle', width: shouldNotOpenInAnotherTab ? '100%' : 'calc(100% - 1.5rem)' }}>
        {document.name}
      </span>
    </>
  )

  const handleDeleteDocument = async documentId => {
    setDocumentToDelete(documentId)
    setOpenDeleteModal(true)
  }

  const handleConfirmDelete = async () => {
    await supabase
      .from('documents')
      .delete()
      .match({ id: documentToDelete })

    onDelete && onDelete()
    onUpdated && onUpdated()
  }

  return (
    <>
      <Modal
        open={openDeleteModal}
        onClose={setOpenDeleteModal}
        doublePadding
        hideHeader
        confirmButtonText='Supprimer'
        onConfirm={handleConfirmDelete}
      >
        Supprimer définitivement cette documentation ?
      </Modal>

      <QuickQuizModal
        open={openModalQuickQuiz}
        document={document}
        onClose={() => setOpenModalQuickQuiz(null)}
        onValidated={() => {
          setOpenModalQuickQuiz(null)
          onUpdated && onUpdated()
        }}
      />

      <Spacing block y={2} />

      <div
        style={{
          borderRadius: spacing['1/2'],
          border: `1px solid ${colors.lightGrey}`,
          boxShadow: '0px 0px 4px rgba(0,0,0,0.05)',
          overflow: 'hidden'
        }}
      >
        <StyledDocument>
          {!shouldNotOpenInAnotherTab
            ? (
              <StyledA href={documentUrl} target='_blank' onClick={handleOpenDocument}>
                <LinkContent />
              </StyledA>
              )
            : (
              <StyledLink to={documentUrl} onClick={handleOpenDocument}>
                <LinkContent />
              </StyledLink>
              )}

          <ButtonContainer>
            {
              (document.status === documentStatusEnum.TO_CREATE.id ||
                (document.update_frequency && (
                  (new Date() - new Date(document.updated_at || document.created_at)) > (document.update_frequency * 1000 * 60 * 60 * 24 * 30)
                )))
                ? (
                    !shouldNotOpenInAnotherTab
                      ? (
                        <Button
                          smallHeight
                          primaryLight
                          smallText
                          small
                          href={documentEditUrl}
                          target='_blank'
                        >
                          {document.status === documentStatusEnum.TO_CREATE.id ? 'À compléter' : 'À mettre à jour'}
                        </Button>
                        )
                      : (
                        <Link to={documentEditUrl}>
                          <Button
                            smallHeight
                            primaryLight
                            small
                            smallText
                          >
                            {document.status === documentStatusEnum.TO_CREATE.id ? 'À compléter' : 'À mettre à jour'}
                          </Button>
                        </Link>
                        )

                  )
                : ((document.validation_type === validationTypeEnum.QUIZ && document.quiz) || (document.validation_type === validationTypeEnum.QUICK_QUIZ && document.quick_quiz)) && !document.users_to_documents[0]?.validated_at
                    ? (
                      <Button
                        onClick={() => { if (document.validation_type === validationTypeEnum.QUICK_QUIZ && !fromContentJs) { setOpenModalQuickQuiz(true) } }}
                        smallHeight
                        primaryLight
                        small
                        smallText
                        href={formatQuizUrl()}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Quizz à valider
                      </Button>
                      )
                    : document.validation_type === validationTypeEnum.OPEN && !document.users_to_documents[0]?.has_opened
                      ? (
                          !shouldNotOpenInAnotherTab
                            ? (
                              <Button
                                onClick={handleOpenDocument}
                                // icon='open_primary.svg'
                                smallHeight
                                primaryLight
                                smallText
                                small
                                href={documentUrl}
                                target='_blank'
                              >
                                À lire
                              </Button>
                              )
                            : (
                              <Link to={documentUrl} onClick={handleOpenDocument}>
                                <Button
                                  smallHeight
                                  primaryLight
                                  small
                                  smallText
                                >
                                  À lire
                                </Button>
                              </Link>
                              )

                        )
                      : document.users_to_documents[0]?.has_opened && (
                        <Tooltip text='Vous avez déjà validé cette documentation'>
                          <Badge circle color={colors.green}>✓</Badge>
                        </Tooltip>
                      )
              }

            <Spacing x={1} />

            <Menu
              Content={({ close }) => (
                <>
                  {
                    shouldNotOpenInAnotherTab
                      ? (
                        <Link to={documentUrl} onClick={handleOpenDocument}>
                          <MenuItem icon='/open_black.svg'>Consulter</MenuItem>
                        </Link>
                        )
                      : (
                        <a
                          target='_blank'
                          href={documentUrl}
                          rel='noreferrer'
                          onClick={handleOpenDocument}
                        >
                          <MenuItem icon='/open_black.svg'>Consulter</MenuItem>
                        </a>
                        )
                  }

                  {(!userContext?.user.organization_id || userContext?.user.role !== userRoles.USER) && !fromContentJs && document.id !== 42 && (window.location.hostname === 'localhost' || window.location.hostname === 'www.documir.com' || currentEnvironment === environments.EXTENSION) && (
                    <>
                      <MenuItem
                        icon='/edit_black.svg'
                        onClick={() => currentEnvironment === environments.EXTENSION ? window.open(`https://www.documir.com/app/documentation/${document.id}/edit`, '_blank').focus() : navigate(`/app/documentation/${document.id}/edit`)}
                      >
                        Modifier
                      </MenuItem>

                      <MenuItem
                        icon='/delete_black.svg'
                        onClick={() => handleDeleteDocument(document.id)}
                      >
                        Supprimer
                      </MenuItem>
                    </>
                  )}
                </>
              )}
            >
              <Button
                smallHeight
                small
                secondary
                icon='/more_horiz.svg'
                onClick={e => {
                  e.preventDefault()
                }}
              />
            </Menu>

            <Spacing x={1} />
          </ButtonContainer>
        </StyledDocument>

        <div
          className='hide-scrollbar'
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflowX: 'scroll',
            // margin: `0 ${spacing[1]} ${spacing[1]} ${spacing[1]}`,
            // paddingTop: spacing[1],
            paddingRight: spacing[1],
            paddingBottom: spacing[1],
            paddingLeft: spacing[1]
          }}
        >
          <style>
            {`
                  .hide-scrollbar::-webkit-scrollbar {
                    display: none;
                  }
                  
                  .hide-scrollbar {
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                  }
                  `}
          </style>

          <div
            style={{
              color: colors.grey,
              fontSize: '15px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Icon icon='/history_grey.svg' />
            <Spacing x='1/2' />
            {new Date(document.updated_at).toLocaleDateString('fr')}
          </div>

          {document?.documents_to_tags?.length
            ? (
              <div
                className='hide-scrollbar'
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  overflowX: 'scroll'
                }}

              >
                {document.documents_to_tags.map(el => (
                  <span key={el.tag_id} style={{ display: 'inline-block', marginLeft: spacing[1] }}>
                    <Badge light isTag>{el.tag_id.tag}</Badge>
                  </span>
                ))}
              </div>
              )
            : null}
        </div>
      </div>
    </>
  )
}
