// import styled from 'styled-components' NE PAS IMPORTER, FAIT PLANTER BUILD
import { useState } from 'react'
import colors from '../colors'
import spacing from '../spacing'
import Button from './Button'
import Link from './Link'
import Spacing from './Spacing'

export default function Modal ({
  onClose,
  children,
  title,
  open,
  padding,
  showOpenDocumirButton,
  onConfirm,
  confirmButtonText,
  hideHeader,
  doublePadding,
  hideCancelButton
}) {
  const [isVisible, setIsVisible] = useState(true)

  const handleClose = () => {
    if (open === undefined) {
      setIsVisible(false)
    } else {
      onClose(false)
    }
  }

  return (open === undefined ? isVisible : open) && (
    <div className='documirModal'>
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          width: '100%',
          height: '100%',
          padding: spacing[2],
          zIndex: '9999999',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <div style={{
          maxWidth: spacing[64],
          maxHeight: spacing[64],
          isolation: 'isolate',
          margin: 'auto',
          backgroundColor: colors.white,
          borderRadius: spacing[1],
          width: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
        >
          {!hideHeader && (
            <div
              style={{
                borderBottom: '1px solid ' + colors.lightGrey,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: spacing[2]
              }}
            >
              {/* <span style={{ marginRight: spacing[2], display: 'inline-block' }}>Fermer</span> */}
              <span style={{ fontWeight: '600', color: colors.black }}>{title || ''}</span>
              <span className='documirModalButton' style={{ cursor: 'pointer', color: colors.grey, padding: `${spacing[0]} ${spacing[1]}` }} onClick={handleClose}>X</span>
            </div>
          )}

          <div style={{ flex: 1, padding: padding || `${spacing[doublePadding ? 4 : 2]} ${spacing[doublePadding ? 4 : 2]}`, overflow: 'auto' }}>
            {children}
          </div>

          <div style={{ borderTop: `1px solid ${colors.lightGrey}`, padding: spacing[2], textAlign: 'right' }}>
            {!hideCancelButton && (
              <Button className='documirModalButton' onClick={handleClose} secondary>{onConfirm ? 'Annuler' : 'Fermer'}</Button>
            )}

            {onConfirm && (
              <>
                <Spacing x={2} />
                <Button className='documirModalButton' onClick={onConfirm} primaryLight>{confirmButtonText || 'Confirmer'}</Button>
              </>
            )}

            {showOpenDocumirButton && (
              <>
                <Spacing x={2} />

                <Link href='https://www.documir.com/app' target='_blank' rel='noreferrer'>
                  <Button
                    className='documirModalButton'
                    primaryLight
                    style={{ flex: 1, textAlign: 'center', padding: spacing[2] }}
                  >
                    Ouvrir Documir
                  </Button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
