
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom'
import { useEffect } from 'react'
import colors from '../colors'
import spacing from '../spacing'

export default function Menu ({ children, Content }) {
  const { x, y, reference, floating, strategy, refs, update } = useFloating({
    placement: 'bottom-end',
    strategy: 'absolute',
    middleware: [offset(8), flip(), shift()]
  })

  const showMenu = () => {
    refs.floating.current.style.display = 'inline-flex'
    update()
  }

  useEffect(() => {
    update()
  }, [x, y])

  const hideMenu = () => {
    if (refs.floating.current) {
      refs.floating.current.style.display = 'none'
    }
  }

  useEffect(() => {
    const handleClick = (event) => {
      if (!refs.reference.current.contains(event.target) && !refs.floating.current.contains(event.target)) {
        hideMenu()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <>
      <div
        onBlur={hideMenu}
        onClick={() => refs.floating.current.style.display === 'none' ? showMenu() : hideMenu()}
        ref={reference}
        style={{ display: 'inline-block', lineHeight: spacing[2] }}
      >
        {children}
      </div>

      <div
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          textAlign: 'left',
          // color: colors.grey,
          maxWidth: spacing[64],
          flexDirection: 'column',
          padding: spacing['1/2'],
          // justifyContent: 'stretch',
          // alignContent: 'stretch',
          // alignItems: 'stretch',
          // minWidth: spacing[32],
          borderRadius: spacing['1/2'],
          // padding: `${spacing['1']} ${spacing['2']}`,
          display: 'none',
          border: '1px solid ' + colors.lightGrey,
          // boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.05)',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.125)',
          backgroundColor: 'white',
          zIndex: '99999'
        }}
      >
        <Content close={hideMenu} />
      </div>
    </>
  )
}
