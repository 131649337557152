export default function Icon ({ icon, width, style, ...props }) {
  return (
    <img
      {...props}
      style={style}
      width={width || '16px'}
      src={(window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://www.documir.com/') + icon}
    />
  )
}
