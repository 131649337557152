import styled from 'styled-components'
import colors from '../colors'
import spacing from '../spacing'
import Icon from './Icon'
import Spacing from './Spacing'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import Menu from './Menu'
import MenuItem from './MenuItem'
import Onboarding from './Onboarding'

const StyledHeader = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  padding-bottom: ${spacing[2]};
`

const StyledHeaderInside = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Settings = styled.span`
  display: inline-block;
  cursor: pointer;
  border-radius: 999rem;
  width: ${spacing[4]};
  height: ${spacing[4]};
  text-align: center;
  padding-top: ${spacing['1/2']};
  flex-shrink: 0;
`

const Documents = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`

export default function Header ({ hasDocumentsNotValidated }) {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <StyledHeader>
      <Onboarding />

      <StyledHeaderInside>

        <Documents>
          {/* <Icon width={20} icon='documir48.png' />
        <Spacing x={1} /> */}
          <span
            style={{
              cursor: 'pointer',
              marginBottom: spacing['1/2'],
              marginTop: spacing['1/2'],
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={() => {
              navigate('/app')
            }}
          >
            <Icon icon='documir48.png' />
          </span>

          <Spacing x='2' />

          <Link
            style={{ fontWeight: '600', fontSize: '1.125rem' }}
            to={
            location.pathname.startsWith('/app/settings')
              ? '/app/settings'
              : location.pathname.startsWith('/app/organization')
                ? '/app/organization'
                : '/app'
          }
          >
            {
            location.pathname.startsWith('/app/settings')
              ? 'Mon compte'
              : location.pathname.startsWith('/app/organization')
                ? 'Mon organisation'
                : 'Documentations'
          }
          </Link>
          <Spacing x='1' />

          {/* <ButtonGroup>
          <Tab isFirst isActive={(location.pathname === '/app/related') || (currentEnvironment === environmentEnums.EXTENSION && location.pathname === '/app')} onClick={() => { navigate('/app/related') }}>liées à cette page</Tab>
          <Tab isActive={(location.pathname === '/app/to-validate') || (currentEnvironment === environmentEnums.WEB && location.pathname === '/app')} onClick={() => { navigate('/app/to-validate') }}>
            à valider
            {
              hasDocumentsNotValidated
                ? <span style={{ display: 'inline-block', width: spacing['1'], backgroundColor: colors.primary, height: spacing['1'], position: 'absolute', top: '-4px', right: '8px', borderRadius: '100rem' }} />
                : ''
            }
          </Tab>
          <Tab isLast isActive={location.pathname === '/app/all'} onClick={() => navigate('/app/all')}>toutes</Tab>
        </ButtonGroup> */}
        </Documents>

        <Menu
          Content={({ close }) => (
            <>
              <Link onClick={close} to='/app'>
                <MenuItem icon='/document_black.svg'>
                  Documentations
                </MenuItem>
              </Link>

              <Link onClick={close} to='/app/organization'>
                <MenuItem icon='/business_black.svg'>
                  Mon organisation
                </MenuItem>
              </Link>

              <Link onClick={close} to='/app/settings'>
                <MenuItem icon='/account_circle_black.svg'>
                  Mon compte
                </MenuItem>
              </Link>
            </>
          )}
        >
          <Settings>
            <Icon width={24} icon='menu.svg' />
          </Settings>
        </Menu>
      </StyledHeaderInside>
    </StyledHeader>
  )
}
