import Modal from './Modal'
import Spacing from './Spacing'
import Input from './Input'
import Button from './Button'
import { useContext, useState } from 'react'
import supabase from '../supabase'
import sendMessageToExtension from '../sendMessageToExtension'
import UserContext from '../contexts/UserContext'

export default function quickQuizModal ({ open, onClose, document, onValidated }) {
  const [selectedOption, setSelectedOption] = useState()
  const [answerIsCorrect, setAnswerIsCorrect] = useState(null)
  const { user } = useContext(UserContext)

  const handleSubmit = async () => {
    if (selectedOption === document.quick_quiz.answer) {
      setAnswerIsCorrect(true)

      await supabase
        .from('users_to_documents')
        .upsert({
          user_id: supabase?.auth?.user()?.id || user?.id,
          document_id: document.id,
          validated_at: new Date()
        })

      sendMessageToExtension({ message: 'getDocuments' })
    } else {
      setAnswerIsCorrect(false)
    }
  }

  return (
    <>
      {
        answerIsCorrect === null
          ? (
            <Modal open={open} onClose={onClose} title='Quizz de validation de la documentation'>
              <div>{document?.quick_quiz?.question}</div>
              <Spacing y={2} />

              <form onSubmit={e => e.preventDefault()}>
                {document?.quick_quiz?.options?.map((option, index) => option && (
                  <div key={index}>
                    {index !== 0 && (<Spacing y={1} />)}
                    <Input onClick={e => setSelectedOption(parseInt(e.target.value, 10))} value={index + 1} name='quickQuiz' type='radio' label={option} />
                  </div>
                ))}
              </form>

              <Spacing y={2} />
              <Button primary width='100%' onClick={handleSubmit}>Valider ma réponse</Button>
            </Modal>
            )
          : (
            <Modal onClose={() => { setAnswerIsCorrect(null); onValidated() }} open={open} title='Quizz de validation de la documentation'>
              {
                answerIsCorrect
                  ? 'Bonne réponse ! Vous venez de valider la connaissance de cette documentation.'
                  : 'Mauvaise réponse ! Merci de bien vouloir relire la documentation, puis repasser ce quizz.'
              }
            </Modal>
            )
      }
    </>
  )
}
