import sendMessageToExtension from '../sendMessageToExtension'
import supabase from '../supabase'
import Button from './Button'

function Logout ({ onLogout }) {
  const handleLogout = async event => {
    event.preventDefault()
    await supabase.auth.signOut()
    sendMessageToExtension({ message: 'logout' })
    onLogout()
  }

  return (
    <form>
      <Button width='100%' secondary icon='logout_black.svg' onClick={handleLogout}>Se déconnecter</Button>
    </form>
  )
}

export default Logout
