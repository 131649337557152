import supabase from './supabase'
import browser from './browser'

export default async function getDocuments (user, executeContentScriptAfter) {
  const userId = supabase.auth.user()?.id || user?.id

  if (userId) {
    const query = supabase
      .from('documents')
      .select(`
        *,
        users_to_documents (
          *
        ),
        documents_to_tags (
          tag_id (
            *
          )
        ),
        documents_to_teams (
          team_id (
            users_to_teams (
              user_id
            )
          )
        )
      `)
      .eq('users_to_documents.user_id', userId)
      .eq('documents_to_teams.team_id.users_to_teams.user_id', userId)
      .order('created_at', { ascending: false })

    const { data, error } = await query

    if (error) {
      console.error(error)
    } else if (data) {
      browser?.storage?.sync.set({ documents: data })

      if (executeContentScriptAfter) {
        browser?.tabs?.query({ active: true }, function (tabs) {
          browser.tabs.sendMessage(tabs[0].id, 'executeContentScript')
        })
      }
    }

    return { data, error }
  }
}
