const defaultSize = 16
const halfSize = parseInt(defaultSize / 2, 10)

const spacing = {
  0: '0px',
  '1/2': parseInt(halfSize * 0.5, 0) + 'px',
  1: parseInt(halfSize * 1, 0) + 'px',
  2: parseInt(halfSize * 2, 0) + 'px',
  4: parseInt(halfSize * 4, 0) + 'px',
  8: parseInt(halfSize * 8, 0) + 'px',
  16: parseInt(halfSize * 16, 0) + 'px',
  32: parseInt(halfSize * 32, 0) + 'px',
  48: parseInt(halfSize * 48, 0) + 'px',
  64: parseInt(halfSize * 64, 0) + 'px'
}

export default spacing
