import { useContext, useEffect, useState } from 'react'
import Logout from './Logout'
import Spacing from './Spacing'
import UserContext from '../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import Card from './Card'
import supabase from '../supabase'

export default function Settings () {
  const { user, setAuthUser } = useContext(UserContext)
  const [emailReminders, setEmailReminders] = useState()
  const navigate = useNavigate()

  const getUserData = async () => {
    const { data } = await supabase
      .from('users')
      .select(`
        new_documents_reminder_every_x_days
      `)
      .eq('id', user?.id)

    setEmailReminders(!!data[0].new_documents_reminder_every_x_days)
  }

  useEffect(() => { // get user data
    if (user?.id) {
      getUserData()
    }
  }, [user?.id])

  const handleReminderChange = async event => {
    event.preventDefault()

    await supabase
      .from('users')
      .update({ new_documents_reminder_every_x_days: event.target.checked ? 7 : null })
      .match({ id: user.id })

    getUserData()
  }

  return (
    <div>
      <Spacing block y={2} />

      <Card header='✉️ Mon adresse email'>
        <div>{user?.email}</div>
      </Card>

      <Spacing block y={2} />

      <Card
        whiteHeader
        isCheckbox
        onChange={handleReminderChange}
        checked={emailReminders}
        header='Activer les emails hebdomadaires de rappel pour les nouvelles documentations à ajouter sur Documir'
        helper='Un email de rappel sera envoyé chaque semaine pour vous rappeler de documenter les dernières nouveautés de la semaine.'
      />

      <Spacing block y={2} />
      <Logout onLogout={() => { setAuthUser(null); navigate('/app') }} />
    </div>
  )
}
