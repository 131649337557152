import styled from 'styled-components'
import colors from '../colors'
import createDefaultCondition from '../createDefaultCondition'
import spacing from '../spacing'
import Button from './Button'
import Condition from './Condition'
import Input from './Input'
import Spacing from './Spacing'
import conditionOptions from '../enums/conditionOptions'

const ConditionContainer = styled.div`
  background-color: ${colors.white};
  border-radius: ${spacing['1/2']};
  /* border: ${props => props.level === 1 ? `1px solid ${colors.lightGrey};` : '0px'}; */
  margin-left: ${props => props.level === 1 ? spacing[0] : spacing[4]};
  padding: ${props => (props.level === 1 && !props.isAlwaysOrNever) ? spacing[1] : '0px'};
  
  ${props => !props.isAlwaysOrNever && `
    margin-bottom: ${spacing[2]};
  `}

  ${props => !props.isAlwaysOrNever && props.level === 1 && `
    box-shadow: 0px 1px 4px rgba(0,0,0,0.125);
    border: 1px solid ${colors.lightGrey};
  `}
`

const AddContainer = styled.div`
  /* display: inline-block; */
  ${props => props.level === 2 && `margin-left: ${spacing[4]};`}
`

export default function ConditionsLevel ({ conditions, setConditions, level }) {
  const operator = conditions.AND ? 'AND' : 'OR'
  const translatedOperator = operator === 'AND' ? 'ET' : 'OU'
  const conditionsItems = conditions[operator]
  const isAlwaysOrNever = conditionsItems[0]?.type === conditionOptions.ALWAYS || conditionsItems[0]?.type === conditionOptions.NEVER

  return (
    <>
      {(conditionsItems || []).map((condition, index) => (
        <ConditionContainer isAlwaysOrNever={isAlwaysOrNever} level={level} key={condition.id}>
          {
            index === 1
              ? (
                <Input type='select' defaultValue={operator} onChange={event => setConditions({ [event.target.value]: conditionsItems })}>
                  <option value='AND'>ET</option>
                  <option value='OR'>OU</option>
                </Input>
                )
              : index !== 0 && (
                <span style={{ width: '4rem', display: 'inline-block', paddingLeft: '0rem' }}>{translatedOperator}</span>
              )
          }

          {index !== 0 && (
            <Spacing x={1} />
          )}

          <Condition
            onChange={newCondition => {
              conditions[operator][index] = newCondition
              setConditions(JSON.parse(JSON.stringify(conditions)))
            }}
            onDelete={id => {
              conditions[operator] = conditions[operator].filter(condition => condition.id !== id)
              setConditions(JSON.parse(JSON.stringify(conditions)))
            }}
            condition={condition}
            isLast={conditionsItems.length === 1}
            isFirst={index === 0}
            level={level}
          />
        </ConditionContainer>
      ))}

      {!isAlwaysOrNever && (
        <AddContainer level={level}>
          <Button width='100%' primaryLight icon='add_primary.svg' onClick={() => setConditions({ [operator]: [...conditions[operator], createDefaultCondition(true, false)] })}>
            Ajouter une condition
          </Button>
        </AddContainer>
      )}
    </>
  )
}
