import { useEffect, useState } from 'react'
import supabase from '../supabase'
import Button from './Button'
import Center from './Center'
import Input from './Input'
import Modal from './Modal'
import Spacing from './Spacing'
import Typography from './Typography'
import currentEnvironment from '../currentEnvironment'
import environments from '../enums/environments'
import { useLocation, useNavigate } from 'react-router-dom'
import colors from '../colors'
import spacing from '../spacing'

function Login ({ onLogin }) {
  const location = useLocation()
  const navigate = useNavigate()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [openModal, setOpenModal] = useState(false)
  const isLoginNotSignup = location?.pathname === '/app/login'
    ? true
    : location?.pathname === '/app/signup'
      ? false
      : currentEnvironment === environments.EXTENSION

  const redirectTo = (currentEnvironment === environments.WEB ? window.location.origin : 'https://www.documir.com') + '/app'

  const handleSignUp = async event => {
    event.preventDefault()

    if (!email) {
      setOpenModal('Merci de renseigner votre adresse email')
    } else if (!password) {
      setOpenModal('Merci de renseigner votre mot de passe')
    } else {
      const { data } = await supabase
        .from('users')
        .select('id')
        .eq('email', email)

      if (data.length) {
        setOpenModal('Un compte a déjà été créé pour cette adresse email')
      } else {
        const { error } = await supabase.auth.signUp({
          email,
          password
        }, { redirectTo })

        if (error) {
          setOpenModal(
            error.message.includes('For security purposes, you can only request this')
              ? 'Par mesure de sécurité, vous êtes limité à une tentative par minute'
              : error.message
                .replace('Password should be at least 6 characters', 'Votre mot de passe doit contenir au moins 6 caractères')
          )
        } else {
          setOpenModal('Merci de vérifier votre adresse email depuis l\'email que nous venons de vous envoyer')
          fetch(`https://www.documir.com/api/alert-me?subject=${encodeURIComponent('Nouvel utilisateur')}&message=${encodeURIComponent(`Nouvel utilisateur : ${email}`)}`)
        }
      }
    }
  }

  const handleGoogleAuth = async () => {
    const redirectTo = (currentEnvironment === environments.EXTENSION ? 'https://www.documir.com' : window.location.origin) + '/app/login'

    if (currentEnvironment === environments.WEB) {
      await supabase.auth.signIn({
        provider: 'google'
      }, {
        redirectTo
      })
    } else {
      window.open(redirectTo + '?googleAuth=true')
    }
  }

  useEffect(() => {
    if (location?.search?.includes('googleAuth=true')) {
      handleGoogleAuth()
    }
  }, [location])

  const handleLogin = async event => {
    event?.preventDefault()

    if (!password) {
      setOpenModal('Merci de renseigner votre mot de passe')
    } else if (!email) {
      setOpenModal('Merci de renseigner votre adresse email')
    } else {
      const { user, error } = await supabase.auth.signIn({
        email,
        password
      }, { redirectTo })

      if (error) {
        setOpenModal(
          error.message.includes('For security purposes, you can only request this')
            ? 'Par mesure de sécurité, vous êtes limité à une tentative par minute'
            : error.message
              .replace('Invalid login credentials', 'Identifiants incorrects')
              .replace('Unable to validate email address: invalid format', "Le format de l'adresse email est incorrect")
              .replace('For security purposes, you can only request this once every 60 seconds', 'Par mesure de sécurité, vous êtes limité à une tentative par minute')
              .replace('Email not confirmed', "Votre adresse n'a pas encore été vérifiée, merci de consulter vos emails")
        )
      } else {
        onLogin(user)
        navigate('/app')
      }
    }
  }

  const handleReset = async () => {
    if (!email) {
      setOpenModal('Merci de renseigner votre adresse email')
    } else {
      const { error } = await supabase.auth.api
        .resetPasswordForEmail(email, { redirectTo })

      if (error) {
        setOpenModal(
          error.message.includes('For security purposes, you can only request this')
            ? 'Par mesure de sécurité, vous êtes limité à une tentative par minute'
            : error.message
              .replace('Unable to validate email address: invalid format', "Le format de l'adresse email est incorrect")
              .replace('User not found', "Aucun utilisateur n'existe pour cette adresse email")
        )
      } else {
        setOpenModal("Un lien de réinitialisation de votre mot de passe vient d'être envoyé par email à " + email)
      }
    }
  }

  return (
    <form onSubmit={e => { e.preventDefault(); if (isLoginNotSignup) { handleLogin() } else { handleSignUp() } }}>
      <Modal open={openModal} onClose={setOpenModal} hideHeader doublePadding>
        {openModal}
      </Modal>

      <Center>
        <Typography textAlign='center' heading>{isLoginNotSignup ? 'Connexion' : 'Inscription'}</Typography>
      </Center>

      <Spacing block y={2} />

      <Button
        icon='/google.svg'
        width='100%'
        secondary
        onClick={handleGoogleAuth}
      >
        Continuer avec Google
      </Button>

      <Spacing block y={2} />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ borderBottom: '1px solid ' + colors.lightGrey, flex: '1' }} />
        <div style={{ margin: `0px ${spacing[2]}`, color: colors.grey }}>ou</div>
        <div style={{ borderBottom: '1px solid ' + colors.lightGrey, flex: '1' }} />
      </div>

      <Spacing block y={2} />
      <Input required fullWidth type='email' label='Email' placeholder='Votre adresse email' onChange={e => setEmail(e.target.value)} />
      <Spacing block y={1} />
      <Input fullWidth onChange={e => setPassword(e.target.value)} type='password' label='Mot de passe' placeholder='Doit contenir au moins 6 caractères' />
      <Spacing block y={2} />

      {isLoginNotSignup
        ? (
          <>
            <Center>
              <button style={{ padding: '0', border: '0', display: 'block', width: '100%' }} type='submit'>
                <Button
                // width={`calc(50% - ${spacing[1]})`}
                  primary
                  width='100%'
                  onClick={handleLogin}
                >
                  Me connecter
                </Button>
              </button>

              <Spacing y={2} block x={2} />

              <Button width='100%' secondary onClick={() => navigate('/app/signup')}>Créer un compte</Button>
            </Center>

            <Spacing block y={1} />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button textButton onClick={handleReset}>Mot de passe oublié ?</Button>

              {currentEnvironment === environments.EXTENSION && (
                <Button icon='open_black.svg' textButton target='_blank' href='https://www.documir.com/app/login'>Ouvrir cette page dans un onglet</Button>
              )}
            </div>
          </>
          )
        : (
          <Center>
            <button style={{ padding: '0', border: '0', display: 'block', width: '100%' }} type='submit'>
              <Button width='100%' primary onClick={handleSignUp}>M'inscrire</Button>
            </button>

            <Spacing y={2} block x={2} />
            <Button width='100%' secondary onClick={() => navigate('/app/login')}>J'ai déjà un compte</Button>
          </Center>
          )}
    </form>
  )
}

export default Login
