import { useContext, useEffect, useState } from 'react'
import supabase from '../supabase'
import Spacing from './Spacing'
import EditorJS from '@editorjs/editorjs'
import editorSettings from '../editorSettings'
import UserContext from '../contexts/UserContext'
import userRoles from '../enums/userRoles'
import Button from './Button'
import { useNavigate, useLocation } from 'react-router-dom'
import buildQuizUrl from '../buildQuizUrl'
import QuickQuizModal from './QuickQuizModal'
import currentEnvironment from '../currentEnvironment'
import environments from '../enums/environments'
import validationTypeEnum from '../enums/validationTypeEnum'

export default function DocumentPage ({ documentId }) {
  let id = documentId // Trying to make it work from content.js but can't because of errors I don't understand

  if (!id) {
    const location = useLocation()
    id = location.pathname.split('/').pop()
  }

  const [document, setDocument] = useState()
  const [editor, setEditor] = useState()
  const [openModalQuickQuiz, setOpenModalQuickQuiz] = useState(null)
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const userId = user?.id
  const quizNotValidated = (document?.validation_type === validationTypeEnum.QUIZ || document?.validation_type === validationTypeEnum.QUICK_QUIZ) &&
    !document?.users_to_documents[0]?.validated_at

  const getDocument = async () => {
    const { data } = await supabase
      .from('documents')
      .select(`
        *,
        users_to_documents (
          *
        )
      `)
      .eq('id', id)
      .eq('users_to_documents.user_id', userId)
      .limit(1)
      .single()

    setDocument(data)
  }

  useEffect(() => {
    if (document && !editor) {
      // Don't remove the "const editor = " > it won't work without it
      setEditor(new EditorJS({
        ...editorSettings({ documentId: id }),
        data: document.content,
        placeholder: 'Cette documentation est vide.',
        readOnly: true
      }))
    }
  }, [document])

  useEffect(() => {
    if (userId) {
      getDocument()
    }
  }, [userId])

  return (
    <div>
      <style>
        {`.codex-editor__redactor {
          margin: 0px !important;
        }`}
      </style>

      <QuickQuizModal
        open={openModalQuickQuiz}
        document={document}
        onClose={() => setOpenModalQuickQuiz(null)}
        onValidated={() => {
          setOpenModalQuickQuiz(null)
          getDocument()
        }}
      />

      <Spacing y={2} />
      <Spacing y={2} />

      {document && (
        <>
          <div style={{ fontWeight: 600, fontSize: '2rem', lineHeight: '3rem' }}>{document.name}</div>
          <Spacing y={2} />
          <Spacing y={1} />

          {/* <div style={{ width: '100%', whiteSpace: 'pre-line' }}>
            {document.content}
          </div> */}

          <div id='editorjs' />
        </>
      )}

      {quizNotValidated && (
        <>
          <Spacing y={2} />
          <Button primary width='100%' onClick={() => { if (document?.validation_type === validationTypeEnum.QUICK_QUIZ) { setOpenModalQuickQuiz(true) } }} href={document?.validation_type === validationTypeEnum.QUIZ ? buildQuizUrl(document.quiz) : null} target={document?.validation_type === validationTypeEnum.QUIZ ? '_blank' : null}>Quizz à valider</Button>
        </>
      )}

      {(user?.role === userRoles.ADMIN || user?.role === userRoles.EDITOR || user?.organization_id) && !documentId && (
        <>
          <Spacing y={2} />
          <Button primaryLight icon='edit_primary.svg' width='100%' onClick={() => currentEnvironment === environments.EXTENSION ? window.open(`https://www.documir.com/app/documentation/${document.id}/edit`, '_blank').focus() : navigate(`/app/documentation/${document.id}/edit`)}>Modifier la documentation</Button>
        </>
      )}
    </div>
  )
}
