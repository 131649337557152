import { useState } from 'react'
import supabase from '../supabase'
import Typography from './Typography'
import Input from './Input'
import Button from './Button'
import Center from './Center'
import Spacing from './Spacing'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'

export default function SetPassword ({ onLogin }) {
  const [password, setPassword] = useState()
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const token = window.location.hash.split('access_token=')[1].split('&')[0]

  const handleSubmit = async event => {
    event.preventDefault()

    if (!password) {
      setOpenModal('Merci de renseigner votre mot de passe')
    } else if (password.length < 6) {
      setOpenModal('Votre mot de passe doit contenir au moins 6 caractères')
    } else {
      supabase.auth.setAuth(token)
      const { user, error } = await supabase.auth.update({ password })

      if (error) {
        setOpenModal(error.message)
      } else if (user.user_metadata.organization_id) {
        const { error } = await supabase
          .from('users')
          .upsert({
            id: user?.id,
            organization_id: user.user_metadata.organization_id,
            role: user.user_metadata.role
          })

        await supabase.auth.update({
          data: { organization_id: null }
        })

        if (error) {
          setOpenModal(error.message)
        }
      }

      onLogin(supabase.auth.user(), { openModalOnboarding: !window.location.hash.includes('type=recovery') })

      if (window.location.hash.includes('type=recovery')) {
        navigate('/app')
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Modal open={openModal} onClose={setOpenModal} hideHeader doublePadding>
        {openModal}
      </Modal>

      <Center>
        <Typography heading>Définir mon mot de passe</Typography>
      </Center>

      <Spacing block y={2} />
      <Input onChange={e => setPassword(e.target.value)} fullWidth label='Votre nouveau mot de passe' type='password' placeholder='Doit contenir au moins 6 caractères' />
      <Spacing block y={2} />

      <Center>
        <Button width='100%' primary onClick={handleSubmit} type='submit' value='Confirmer'>Confirmer</Button>
      </Center>
    </form>
  )
}
