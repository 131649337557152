import styled from 'styled-components'
import spacing from '../spacing'

const StyledSpacing = styled.div`
  display: ${props => (props.block || (props.y && !props.x)) ? 'block' : 'inline-block'};
  width: ${props => props.x ? spacing[props.x] : 'Opx'};
  height: ${props => props.y ? spacing[props.y] : 'Opx'};
`

export default function Spacing (props) {
  return (
    <StyledSpacing {...props} />
  )
}
