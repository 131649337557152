import conditionOptions from './enums/conditionOptions'

export default function createDefaultCondition (withoutOperator, isFirst, options = {}) {
  const condition = isFirst && options?.from
    ? {
        id: new Date().toISOString(),
        type: conditionOptions.URL_CONTAINS,
        value: options?.from
      }
    : isFirst
      ? {
          id: new Date().toISOString(),
          type: conditionOptions.ALWAYS
        }
      : {
          id: new Date().toISOString(),
          type: conditionOptions.URL_CONTAINS
        }

  if (withoutOperator) {
    return condition
  } else {
    return { AND: [condition] }
  }
}
