const documentStatusEnum = {
  TO_UPDATE: {
    id: 'TO_UPDATE',
    label: 'À mettre à jour'
  },
  TO_CREATE: {
    id: 'TO_CREATE',
    label: 'À rédiger'
  }
}

export default documentStatusEnum
